import React, { memo, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { Drawer, Tooltip } from "@material-ui/core";
import { BREAKPOINTS_VALUE } from "Styles/Constants";
import { APIS_END_POINTS, URL_LOGIN } from "Helpers/Paths";
import { logOutUser } from "Redux/Auth/Actions";
import { toggleSidebar, changeVisibilitySidebar } from "Redux/General/Actions";
import { icons } from "./icons";
import { LINKS } from "./Links";
import { header } from "../../../Helpers/DefaultValues";
import Api from "../../../Helpers/ApiHandler";
import BirdIcon from "Assets/Images/white-bird.svg";
import { MainWrapper, SideBarWrapper } from "./SideBar.style";

const mapReduxStateToProps = (state) => ({
  userDetails: state.Auth.userDetails,
});

const mapDispatchToProps = (dispatch) => ({
  logOutUser: () => dispatch(logOutUser()),
});

function SideBar(props) {
  const settings = useSelector((state) => state.General.sidebar);
  const dispatch = useDispatch();
  const subscriptionData = useSelector(
    (state) => state.Subscription.subscriptionData
  );
  const [links, setLinks] = useState([]);

  const fetchRoleWiseLinks = () => {
    const userDetails = props.userDetails;
    let temp = [];
    if (userDetails.roleType === "SUPERADMIN") {
      temp = LINKS;
    } else if (userDetails.roleType === "ADMIN") {
      LINKS.forEach((data) => {
        if (
          data?.name !== "add-organization" &&
          data?.name !== "organizations-list" &&
          data?.name !== "statistics"
        ) {
          temp.push(data);
        }
      });
    } else {
      LINKS.forEach((data) => {
        if (
          userDetails.roleType === "EDITOR" ||
          userDetails.roleType === "VIEWER"
        ) {
          if (
            data?.name !== "add-members" &&
            data?.name !== "pricing" &&
            data?.name !== "add-organization" &&
            data?.name !== "organizations-list" &&
            data?.name !== "statistics"
          ) {
            temp.push(data);
          }
        } else {
          if (
            data.name !== "add-members" &&
            data.name !== "pricing" &&
            data?.name !== "add-organization" &&
            data?.name !== "organizations-list" &&
            data?.name !== "statistics"
          ) {
            temp.push(data);
          }
        }
      });
    }
    setLinks(temp);
  };

  useEffect(() => {
    fetchRoleWiseLinks();
  }, []);

  useEffect(() => {
    // THIS FUNCTION ADDED JUST TO PASS IN REMOVE LISTENER
    function resizeListener() {
      // SET SIDEBAR SCROLL HEIGHT
      // setHeight(window.innerHeight)

      // CHANGE SIDEBAR TYPE
      let newValue = visibilityCheck();
      if (newValue) {
        dispatch(toggleSidebar(true));
      }

      if (newValue !== settings.visible) {
        dispatch(changeVisibilitySidebar(newValue));
        if (!newValue) closeSidebar();
      }
    }
    window.addEventListener("resize", resizeListener);

    return function removeListener() {
      window.removeEventListener("resize", resizeListener);
    };
    // eslint-disable-next-line
  }, [settings.visible]);

  /*const closeSidebar = () => {
        dispatch(toggleSidebar(false));
    };*/
  const closeSidebar = () => {
    dispatch(toggleSidebar(!settings.open));
  };

  const handleLogOut = (e) => {
    e.preventDefault();

    new Api()
      .post(APIS_END_POINTS.COMPANY_LOGOUT, { headers: header })
      .then((response) => {
        unsubscribeTokeToTopic(JSON.parse(localStorage.getItem("details")).id);

        // localStorage.removeItem("accessToken");
        // localStorage.removeItem("tokenId");
        //
        // props.logOutUser()
        // props.history.push(URL_LOGIN)
        // window.location.reload();
      })
      .catch((error) => {
        props.logOutUser();
        props.history.push(URL_LOGIN);
        window.location.reload();
      });
  };

  const unsubscribeTokeToTopic = (id) => {
    const FCMToken = localStorage.getItem("token");

    if (FCMToken && FCMToken !== "") {
      fetch("https://iid.googleapis.com/iid/v1:batchRemove", {
        method: "POST",
        headers: new Headers({
          Authorization: "key=" + process.env.REACT_APP_FCM_SERVER_KEY,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          to: "/topics/" + id,
          registration_tokens: [FCMToken],
        }),
      })
        .then((response) => {
          if (response.status < 200 || response.status >= 400) {
            throw new Error(
              "Error subscribing to topic: " +
                response.status +
                " - " +
                response.text()
            );
          }

          localStorage.removeItem("accessToken");
          localStorage.removeItem("tokenId");

          props.logOutUser();
          props.history.push(URL_LOGIN);
          window.location.reload();
        })
        .catch((error) => {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("tokenId");

          props.logOutUser();
          props.history.push(URL_LOGIN);
          window.location.reload();
        });
    } else {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("tokenId");

      props.logOutUser();
      props.history.push(URL_LOGIN);
      window.location.reload();
    }
  };

  let checkVariant = visibilityCheck();

  if (checkVariant && settings.open) checkVariant = "permanent";
  else checkVariant = "temporary";

  const redirectTo = (item) => {
    if (subscriptionData || props.userDetails.roleType === "SUPERADMIN") {
      props.history.push(item.path);
      return;
    } else {
      //props.history.push(URL_PRICING)
      props.history.push(item.path);
    }
  };

  return (
    <MainWrapper>
      <Drawer
        classes={{
          paper: `drawer ${!settings.open && "mini"}`,
        }}
        anchor="left"
        open={true}
        variant={"permanent"}
      >
        <SideBarWrapper
          className={`sidebar ${!settings.open && "mini"}`}
          open={settings.open}
        >
          <div
            className="header-title"
            style={{ cursor: "pointer" }}
            onClick={() => closeSidebar()}
          >
            {settings.open ? (
              <img src={BirdIcon} alt="Bird Logo" />
            ) : (
              <div className="logo" />
            )}
          </div>
          <div className="links">
            {links.map((item) => (
              <div
                className={`sidebarMenuItems ${
                  !settings.open && "sidebarMenuItemsCollapsed"
                }`}
                onClick={(e) =>
                  item.name === "logout" ? handleLogOut(e) : redirectTo(item)
                }
                key={item.id}
              >
                <Tooltip title={item.title}>
                  <div className="sidebarMenuItemsIcon">{icons[item.name]}</div>
                </Tooltip>
                {settings.open && <div className="text">{item.title}</div>}
              </div>
            ))}
          </div>
        </SideBarWrapper>
      </Drawer>
    </MainWrapper>
  );
}

export function visibilityCheck() {
  return window.innerWidth >= BREAKPOINTS_VALUE.LAPTOP;
}

export default connect(
  mapReduxStateToProps,
  mapDispatchToProps
)(withRouter(memo(SideBar)));

/* <div className={`notification flex f-h-center ${!settings.open && "mini"}`}>
<span className={`notification-icon ${!settings.open && "mini"}`}>
    <Tooltip title={"Notification"}>
        <img
            src={BellIcon}
            alt="Bell"
            width="20px"
            height="24px"
        />
    </Tooltip>
</span>
<span className={`notification-text ${!settings.open && "mini"}`}> Notifications </span>
</div>

<div className={`box ${!settings.open && "mini"}`}>
<div className={`box-header flex f-v-center ${!settings.open && "mini"}`}>
    <span className={`box-header-name ${!settings.open && "mini"}`}> User Information </span>
    <span className="box-header-icon flex f-h-center" style={{ cursor: "pointer" }} onClick={editUserData}>
        <Tooltip title={"User Information"}>
            <img
                src={EditIcon}
                alt="Edit"
            />
        </Tooltip>
    </span>
</div>

{settings.open &&
    <div className="box-content">
        <img
            // src={ProfileIcon}
            src={details.image}
            alt="Profile Icon"
            height={"50px"}
            width={"50px"}
            style={{ borderRadius: "50%" }}
        />
        <div className="box-content-name"> {details.firstName} {details.lastName} </div>
        <div className="box-content-mobile"> {details.phone} </div>
        <div className="box-content-email"> {details.email} </div>
    </div>
}
</div>

<div className={`box ${!settings.open && "mini"}`}>
<div className={`box-header flex f-v-center ${!settings.open && "mini"}`}>
    <div className={`box-header-name ${!settings.open && "mini"}`}> Organization </div>
    <div className="box-header-icon flex f-h-center" style={{ cursor: "pointer" }} onClick={editOrganizationData}>
        <Tooltip title={"Organization"}>
            <img
                src={EditIcon}
                alt="Edit"
            />
        </Tooltip>
    </div>
</div>

{settings.open &&
    <div className="box-content">
        <div className="box-content-name no-margin-top"> {details.name} </div>
        <div className="box-content-mobile"> {details.phone} </div>
        <div className="box-content-email"> {details.address} </div>
    </div>
}
</div>

{settings.open ? <Button
variant="outlined"
className="btn"
fullWidth
onClick={() => props.history.push(URL_PROJECT_MEMBERS_LISTING)}
>
Company Members
</Button>
:
<Tooltip title="Company Members">
    <div className="member-icon" />
</Tooltip>
}

{settings.open ? <Button
variant="outlined"
className="btn"
fullWidth
onClick={() => props.history.push(URL_PROJECT_LISTING)}
>
Your Projects
</Button>
:
<Tooltip title="Your Projects">
    <div className="member-icon" />
</Tooltip>
}

{settings.open ? <Button
variant="outlined"
className="btn"
fullWidth
onClick={(e) => handleLogOut(e)}
>
Log Out
</Button>
:
<Tooltip title="Log Out">
    <div className="member-icon" />
</Tooltip> */
