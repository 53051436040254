import { ACTION_TYPES } from "./Actions";
import { visibilityCheck as vCS } from "Components/Commons/SideBar/SideBar";

const initState = {
  toast: {
    enable: false,
    message: "",
  },
  sidebar: {
    open: vCS(),
    visible: vCS(),
  },
  projectView: "CARD",
  projectOwnerType: "MyProject",
  projectInterestId: "",
  projectSearch: "",
  selectedCompany: "",
};

const Reducer = (state = initState, action) => {
  switch (action.type) {
    case ACTION_TYPES.TOAST_MESSAGE_ENABLE:
      return {
        ...state,
        toast: {
          enable: true,
          message: action.message,
        },
      };

    case ACTION_TYPES.TOAST_MESSAGE_DISABLE:
      return {
        ...state,
        toast: {
          enable: false,
          message: "",
        },
      };

    case ACTION_TYPES.TOGGLE_VIEW:
      const projectView =
        state.projectView === initState.projectView ? "LIST" : "CARD";
      return {
        ...state,
        projectView,
      };

    case ACTION_TYPES.SIDEBAR_TOGGLE:
      let newLSettings = { ...state.sidebar };
      newLSettings.open =
        typeof action.value === "boolean" ? action.value : !newLSettings.open;
      return {
        ...state,
        sidebar: newLSettings,
      };

    case ACTION_TYPES.SIDEBAR_VISIBILITY:
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          visible: action.value,
        },
      };

    case ACTION_TYPES.PROJECT_OWNER:
      // const projectOwnerType = (state.projectOwnerType === initState.projectOwnerType) ? "MyProject" : "OrganizationProject"
      return {
        ...state,
        projectOwnerType: action.projectOwner,
      };

    case ACTION_TYPES.INTEREST_ID:
      // const projectOwnerType = (state.projectOwnerType === initState.projectOwnerType) ? "MyProject" : "OrganizationProject"
      return {
        ...state,
        projectInterestId: action.projectInterestId,
      };

    case ACTION_TYPES.PROJECT_SEARCH:
      // const projectOwnerType = (state.projectOwnerType === initState.projectOwnerType) ? "MyProject" : "OrganizationProject"
      return {
        ...state,
        projectSearch: action.projectSearch,
      };

    case ACTION_TYPES.SELECTED_COMPANY:
      return {
        ...state,
        selectedCompany: action.selectedCompany,
      };
    default:
      return state;
  }
};

export default Reducer;
