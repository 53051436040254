import Auth from "./Auth/Reducer";
import General from "./General/Reducer";
import Subscription from "./Subscription/Reducer";

const reducers = {
    Auth,
    General,
    Subscription
}

export default reducers