import styled from "styled-components";
import { COLORS, FONTS, responsive } from "Styles/Constants";
import { Form } from "formik";

export const MainWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .btn {
    background-color: ${COLORS.SECONDARY};
    color: ${COLORS.PRIMARY};
    font-family: ${FONTS.PRIMARY_MEDIUM};
    font-size: 18px;
    text-transform: capitalize;
    border-radius: 2em;

    :hover {
      background-color: ${COLORS.SECONDARY};
    }
  }

  .text-field {
    color: ${COLORS.SECONDARY};
    margin-bottom: 25px;
  }

  .MuiFormLabel-root {
    color: ${COLORS.SECONDARY};
    font-family: ${FONTS.PRIMARY_BOLD};
    font-size: 18px;
  }

  .MuiInputBase-input {
    color: #c1b04f;
    font-family: ${FONTS.PRIMARY_MEDIUM};
    font-size: 19px;
  }

  .MuiInput-root {
    width: 100%;
  }

  .MuiInput-underline:before {
    border-bottom: 2px solid ${COLORS.SECONDARY};
  }

  .flex {
    align-items: center;
  }

  .dataContainer {
    display: flex;
    gap: 20px;
    margin-bottom: 10px;
  }

  .datePicker {
    width: 100%;
  }
`;
export const SignUpWrapper = styled.div`
  width: 448px;
  color: ${COLORS.SECONDARY};

  .header {
    font-family: ${FONTS.PRIMARY_BOLD};
    flex-direction: column;

    .title {
      margin: 40px auto;
      font-size: 30px;
    }
  }

  .footer {
    flex-direction: column;
    text-align: center;

    a {
      color: ${COLORS.SECONDARY};
      text-decoration: none;
      font-size: 15px;
      font-family: ${FONTS.PRIMARY_MEDIUM};

      :hover {
        text-decoration: underline;
      }
    }

    .sign-up {
      margin: 12px auto;
    }

    .register-btn {
      width: 264px;
      height: 40px;
      margin: 25px auto 25px;
    }
  }
`;

export const FormWrapper = styled(Form)`
  margin: 0 auto;
  width: 100%;

  .profile-text {
    margin-bottom: 15px;
  }

  .image-wrapper {
    cursor: pointer;
    margin: 5px auto 15px;
    height: 105px;
    width: 105px;
    background-size: cover;
    background-position: center;

    ${responsive.DISPLAY`
			border-width : 3px;
			height	: 80px;
			width	: 80px;
		`}
  }

  .input-wrapper {
    .input-label-wrapper {
      margin: 5px 0;

      ${responsive.DISPLAY`margin: 0;`}
    }
  }

  .button-wrapper {
    margin-top: 45px;

    .styled {
      width: 200px;
      margin: 0 20px;

      ${responsive.DISPLAY`width: 175px;`}
    }
  }

  .label {
    color: ${COLORS.SECONDARY};
    font-family: ${FONTS.PRIMARY_BOLD};
    font-size: 13px;

    text-transform: uppercase;
  }
`;
