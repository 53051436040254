import Quill from "quill";
const Inline = Quill.import('blots/inline');

class CustomColor extends Inline {
    constructor(domNode, value) {
        super(domNode, value);
    
        const span = this.replaceWith(new Inline(Inline.create()));
    
        span.children.forEach(child => {
          if (child.attributes) child.attributes.copy(span);
          if (child.unwrap) child.unwrap();
        });
    
       // here we apply every attribute from <font> tag to span as a style
        Object.keys(domNode.attributes).forEach(function (key) {
          
            if (domNode.attributes[key].name != "style")
            {
              var value = domNode.attributes[key].value;
              var name = domNode.attributes[key].name;
              if (name == "face")
                name = "font-family";
              span.format(name, value);
            }
      });
    
        this.remove();
    
        return span;
      }
}
CustomColor.blotName = "customColor";
CustomColor.tagName = "FONT";
export function RegisterQuillWrapper() {
    Quill.register(CustomColor, true);
}
