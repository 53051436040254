import React, { Component } from "react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    this.setState({
      hasError: true,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <h1>Oops...</h1>
          <h3>Something went wrong.</h3>
          <h5>Please refresh page & try again.</h5>
          <h5>
            If you still face same issue then, please contact a software
            engineer.
          </h5>
        </>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
