import * as Yup from "yup";
import { Messages } from "./Messages";

export function isIEBrowser() {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf("MSIE ");
  const msie11 = ua.indexOf("Trident/");
  return msie > 0 || msie11 > 0;
}

export const saveCheckList = (key) => {
  const checklistObj = localStorage.getItem("checkList");
  let currentObj = {};

  if (checklistObj) {
    currentObj = { ...JSON.parse(checklistObj) };
  }
  currentObj[key] = true;

  localStorage.setItem("checkList", JSON.stringify(currentObj));
};

export function generateValidationSchema(inputs) {
  let validationObject = {};

  inputs.forEach((input) => {
    let { validation, name } = input;
    if (input.validation) {
      let yup = validation.number ? Yup.number() : Yup.string();

      /* GENERAL */

      if (validation.required)
        yup = yup.required(Messages.validation[name].required);

      if (validation.equals)
        yup = yup.oneOf(
          [Yup.ref(validation.equals), null],
          Messages.validation[name].equals
        );

      /* NUMBER TYPE */
      if (validation.number)
        yup = yup.typeError(Messages.validation[name].number);

      if (validation.min)
        yup = yup.min(validation.min, Messages.validation[name].min);

      if (validation.positive)
        yup = yup.positive(Messages.validation[name].positive);

      if (validation.integer)
        yup = yup.integer(Messages.validation[name].integer);

      /* SPECIFIC */
      if (validation.email) yup = yup.email(Messages.validation[name].valid);

      if (validation.phoneNumber)
        yup = yup.matches(phoneRegExp, Messages.validation[name].valid);

      validationObject[name] = yup;
    }
  });

  return Yup.object(validationObject);
}

export const phoneRegExp = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;

export function formatPhoneNumber(phoneNumberString) {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return null;
}

export function getNumberFromMaskedPhoneNumber(phoneNumber) {
  const numberList = phoneNumber ? phoneNumber.match(/\d/g) : "";
  const number = numberList ? numberList.join("") : "";

  return number;
}

export function getFileName(url) {
  const awsFileName = url.split("/").pop();
  const awsFileNameList = awsFileName.split(".");
  const extension = awsFileNameList[awsFileNameList.length - 1];
  const fileNameList = awsFileNameList[0].split("_");
  fileNameList.pop();
  fileNameList.pop();
  const fileName = fileNameList.join("_");

  return fileName + "." + extension;
}
