export const ACTION_TYPES = {
  FETCH_SUBSCRIPTION: "FETCH_SUBSCRIPTION",
};

export const fetchCompanySubscription = (values) => {
  return {
    type: ACTION_TYPES.FETCH_SUBSCRIPTION,
    values,
  };
};
