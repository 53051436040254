export const ACTION_TYPES = {
  TOAST_MESSAGE_ENABLE: "TOAST_MESSAGE_ENABLE",
  TOAST_MESSAGE_DISABLE: "TOAST_MESSAGE_DISABLE",
  TOGGLE_VIEW: "TOGGLE_VIEW",
  PROJECT_OWNER: "PROJECT_OWNER",
  SIDEBAR_TOGGLE: "SIDEBAR_TOGGLE",
  SIDEBAR_VISIBILITY: "SIDEBAR_VISIBILITY",
  INTEREST_ID: "INTEREST_ID",
  PROJECT_SEARCH: "PROJECT_SEARCH",
  SELECTED_COMPANY: "SELECTED_COMPANY",
};

export const showToast = (message) => {
  return {
    type: ACTION_TYPES.TOAST_MESSAGE_ENABLE,
    message: message || "",
  };
};

export const hideToast = () => {
  return {
    type: ACTION_TYPES.TOAST_MESSAGE_DISABLE,
  };
};

export const toggleView = () => {
  return {
    type: ACTION_TYPES.TOGGLE_VIEW,
  };
};

/* SIDEBAR */
export const toggleSidebar = (value) => {
  return {
    type: ACTION_TYPES.SIDEBAR_TOGGLE,
    value,
  };
};

export const changeVisibilitySidebar = (value) => {
  return {
    type: ACTION_TYPES.SIDEBAR_VISIBILITY,
    value,
  };
};

export const projectOwner = (projectOwner) => {
  return {
    type: ACTION_TYPES.PROJECT_OWNER,
    projectOwner,
  };
};

export const projectInterestId = (projectInterestId) => {
  return {
    type: ACTION_TYPES.INTEREST_ID,
    projectInterestId,
  };
};

export const projectSearch = (projectSearch) => {
  return {
    type: ACTION_TYPES.PROJECT_SEARCH,
    projectSearch,
  };
};

export const changeCompany = (selectedCompany) => {
  return {
    type: ACTION_TYPES.SELECTED_COMPANY,
    selectedCompany,
  };
};
