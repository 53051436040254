import React from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import { URL_LOGIN } from "Helpers/Paths";

function ProtectedRoute(props) {
  const { isLoggedIn, children } = props;

  return <>{!isLoggedIn ? <Redirect to={URL_LOGIN} /> : children}</>;
}

const mapReduxStateToProps = (state) => ({
  isLoggedIn: state.Auth.isLoggedIn,
});

export default connect(mapReduxStateToProps, null)(withRouter(ProtectedRoute));
