import { ACTION_TYPES } from "./Actions";

const initState = {
  subscriptionData: null,
};

const Reducer = (state = initState, action) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_SUBSCRIPTION:
      return {
        ...state,
        subscriptionData: action.values,
      };

    default:
      return state;
  }
};

export default Reducer;
