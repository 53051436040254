import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import BirdIcon from "../../../Assets/Images/black-bird.svg";
import {SignUpWrapper} from "../SignUp/SignUp.style";
import {MainWrapper, TermsAndConditionWrapper} from "./TermsAndCondition.style";
import Api from "../../../Helpers/ApiHandler";
import {APIS_END_POINTS} from "../../../Helpers/Paths";
import {header} from "../../../Helpers/DefaultValues";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.bubble.css'


class TermsAndCondition extends Component {

    constructor(props) {
        super(props);
        this.state = {
            termsAndConditionText: "",
        }
    }

    getTermsAndConditionText() {
        //GET API CALL

        new Api().get(APIS_END_POINTS.GET_TERMS_AND_CONDITION,{headers:header})
            .then(response=>{
                this.setState({
                    termsAndConditionText : response
                })
            })
            .catch(error=>{

            })
    }

    componentDidMount() {
        this.getTermsAndConditionText();
    }

    render() {
        return (
            <MainWrapper>
                <TermsAndConditionWrapper>
                    <div className="header flex">
                        <img
                            src={BirdIcon}
                            alt="Bird Logo"
                            width="234px"
                            height="172px"
                        />
                        <div className="title"> Terms And Condition</div>
                        <ReactQuill
                            value={this.state.termsAndConditionText}
                            readOnly={true}
                            theme={"bubble"}
                        />
                    </div>
                </TermsAndConditionWrapper>
            </MainWrapper>
        );
    }
}

export default (withRouter(TermsAndCondition));