import styled from "styled-components";

import { COLORS, FONTS } from "Styles/Constants";

export const MainWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .btn {
    background-color: ${COLORS.SECONDARY};
    color: ${COLORS.PRIMARY};
    font-family: ${FONTS.PRIMARY_MEDIUM};
    font-size: 18px;
    text-transform: capitalize;
    border-radius: 2em;

    :hover {
      background-color: ${COLORS.SECONDARY};
    }
  }

  .text-field {
    color: ${COLORS.SECONDARY};
    margin-bottom: 25px;
    font-family: ${COLORS.PRIMARY_MEDIUM};
    font-size: 18px;
  }

  .MuiFormLabel-root {
    color: ${COLORS.SECONDARY};
    font-family: ${FONTS.PRIMARY_BOLD};
    font-size: 18px;
  }

  .MuiInputBase-input {
    color: #c1b04f;
    font-family: ${FONTS.PRIMARY_MEDIUM};
    font-size: 19px;
  }

  .MuiInput-underline:before {
    border-bottom: 2px solid ${COLORS.SECONDARY};
  }
`;
export const LoginWrapper = styled.div`
  width: 448px;
  color: ${COLORS.SECONDARY};

  .header {
    font-family: ${FONTS.PRIMARY_BOLD};
    flex-direction: column;

    img {
      margin: auto;
      margin-top: 40px;
      width: 234px;
      height: 172px;
    }

    .title {
      margin: 40px auto;
      font-size: 30px;
    }
  }

  .footer {
    flex-direction: column;
    text-align: center;

    a {
      color: ${COLORS.SECONDARY};
      text-decoration: none;
      font-size: 15px;
      font-family: ${FONTS.PRIMARY_MEDIUM};

      :hover {
        text-decoration: underline;
      }
    }

    .f-password {
      margin: 13px auto;
    }

    .login-btn {
      width: 264px;
      height: 40px;
      margin: 12px auto;
    }

    .sign-up {
      margin: 12px auto;
    }

    .facebook {
      margin: 70px auto 25px;

      .facebook-text {
        margin-right: 10px;
        font-size: 15px;
        font-family: ${FONTS.PRIMARY_MEDIUM};
      }

      .icon {
        background-color: #385c8e;
        width: 40px;
        height: 40px;
        margin: auto;
        border-radius: 50%;
      }
    }
  }
`;
