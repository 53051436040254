import React from "react";
import styled, { keyframes } from "styled-components";
import { Button, InputBase, Select } from "@material-ui/core";
import { FONTS, COLORS, responsive } from "Styles/Constants";

/* INPUT */
export const TextField = styled(InputBase)`
  input {
    color: rgba(255, 255, 255, 0.8);
    font-family: ${FONTS.SECONDARY};
    transition: 0.3s;

    ${responsive.DISPLAY`font-size : 13px;`}

    &:focus {
      border-color: ${COLORS.SECONDARY};
    }

    /* ${responsive.DISPLAY`padding : 13px 20px;`}*/
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  text-align: left;
  margin-bottom: 25px;
  .label {
    color: ${COLORS.SECONDARY};
    font-family: ${FONTS.PRIMARY_BOLD};
    font-size: 13px;

    text-transform: uppercase;

    /*${responsive.DISPLAY`font-size : 11px;`}*/
  }

  /* ${TextField} {

        ${responsive.DISPLAY`margin-top: 7px;`}
    }*/

  .error {
    bottom: -15px;
    color: ${COLORS.DANGER};
    font-size: 13px;
    left: 10px;
    position: absolute;
  }

  border-bottom: 2px solid ${COLORS.SECONDARY};
`;

const ripple = keyframes`
    0% {
        opacity : 1;
        transform: scale(0);
    }

    20%, 100% {
        opacity : 0;
        transform: scale(100);
    }
`;
export const ProgressWrapper = styled.div`
  width: 100px;
  height: 100px;
  position: relative;
  margin: 0 auto;

  .img-wrapper {
    position: relative;
    z-index: 1;
    .img {
      width: 50%;
    }
  }

  .ripple-wrapper {
    .ripple {
      height: 1px;
      width: 1px;
      border-radius: 50%;

      position: absolute;
      top: 50%;
      left: 50%;

      animation: ${ripple} 5s ${({ animation }) => animation || "ease"} infinite;

      &.blue {
        background-color: #4495a9;
      }
      &.green {
        background-color: #7bc8a9;
        animation-delay: 1s;
      }
      &.yellow {
        background-color: #e9dfa7;
        animation-delay: 2s;
      }
      &.orange {
        background-color: #f7a158;
        animation-delay: 3s;
      }
      &.red {
        background-color: #d02027;
        animation-delay: 4s;
      }
    }
  }
`;

export const InputWrapper1 = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  text-align: left;
  margin-bottom: 25px;
  .label {
    color: ${COLORS.SECONDARY};
    font-family: ${FONTS.PRIMARY_BOLD};
    font-size: 13px;

    text-transform: uppercase;
    letter-spacing: 1px;

    /*${responsive.DISPLAY`font-size : 11px;`}*/
  }

  .error {
    bottom: -15px;
    color: ${COLORS.DANGER};
    font-size: 13px;
    left: 10px;
    position: absolute;
  }
`;

/* INPUT */
export const TextField1 = styled(InputBase)`
  width: 100%;
  margin-top: 5px;
  input {
    background-color: ${COLORS.PRIMARY_LIGHT};
    height: 45px;
    border-width: 1px;
    border-style: solid;
    border-color: ${COLORS.PRIMARY_DARK};
    border-radius: 10px;
    color: ${COLORS.SECONDARY};
    font-family: ${FONTS.SECONDARY};
    // background-color : ${COLORS.PRIMARY};
    padding: 0 10px 0 10px;
    transition: 0.3s;

    /*        ${responsive.DISPLAY`font-size : 13px;`}*/

    &:focus {
      border-color: ${COLORS.PRIMARY_BOX_SHADOW_COLOR};
    }

    /*${responsive.DISPLAY`padding : 13px 20px;`}*/
  }
`;

export const TextField2 = styled(Select)`
  // width : 100%;

  /* .MuiOutlinedInput-notchedOutline{
                border-color    : ${COLORS.PRIMARY_DARK};
        };
        .MuiOutlinedInput-notchedOutline : hover .MuiOutlinedInput-notchedOutline{
                border-color    : ${COLORS.PRIMARY_DARK};
        };*/
`;
