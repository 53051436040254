import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Button } from "@material-ui/core";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import Loader from "react-loader-advanced";
import { connect } from "react-redux";
import * as yup from "yup";
import { Link } from "react-router-dom";
import { InputWithLabel, ProgressRipple } from "../../Commons/Commons";
import {
  APIS_END_POINTS,
  URL_LOGIN,
  URL_PROJECT_LISTING,
} from "../../../Helpers/Paths";
import { loginUser, updateUserDetails } from "../../../Redux/Auth/Actions";
import Api from "../../../Helpers/ApiHandler";
import BirdIcon from "../../../Assets/Images/black-bird.svg";
import { MainWrapper, SignUpWrapper } from "./SignUp.style";
import moment from "moment";

const validationSchema = yup.object({
  firstName: yup
    .string("Enter your first name")
    .required("First name is required"),
  lastName: yup
    .string("Enter your last name")
    .required("Last name is required"),
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  phone: yup
    .number("Enter your phone number")
    .required("Phone number is required"),
  password: yup
    .string("Enter your password")
    .required("Password is required")
    .min(6, "Password must be at least 6 characters"),
  name: yup.string("Enter your name").required("Name is required"),
  address: yup.string("Enter your address").required("Address is required"),
  area: yup.string("Enter your area").required("Area is required"),
  city: yup.string("Enter your city").required("City is required"),
  country: yup.string("Enter your country").required("Country is required"),
});

const mapReduxStateToProps = (state) => ({
  isLoggedIn: state.Auth.isLoggedIn,
  userDetails: state.Auth.userDetails,
});

const mapDispatchToProps = (dispatch) => ({
  loginUser: () => dispatch(loginUser()),
  updateUserDetails: (data) => {
    dispatch(updateUserDetails(data));
  },
});

const SignUp = (props) => {
  const [loading, setLoading] = useState(false);
  const inputs = [
    {
      label: "Name",
      name: "name",
      validation: { required: true },
    },
    {
      label: "Address",
      name: "address",
      validation: { required: true },
    },
    {
      label: "Area",
      name: "area",
      validation: { required: true },
    },
    {
      label: "City",
      name: "city",
      validation: { required: true },
    },
    {
      label: "Country",
      name: "country",
      validation: { required: true },
    },
    {
      label: "First Name",
      name: "firstName",
      validation: { required: true },
    },
    {
      label: "Last Name",
      name: "lastName",
      validation: { required: true },
    },
    {
      label: "Email",
      name: "email",
      validation: { required: true, email: true },
    },
    {
      label: "Password",
      name: "password",
      type: "password",
      validation: { required: true, min: 6 },
    },
    {
      label: "Phone Number",
      name: "phone",
      validation: {
        required: true,
        number: true,
        positive: true,
        integer: true,
      },
    },
  ];

  const checkIfAlreadyLoggedIn = () => {
    if (props.isLoggedIn) props.history.push(URL_PROJECT_LISTING);
  };

  const handleAutocompleteChange = (address) => {
    formik.setFieldValue("address", address);
  };
  const handleAutocompleteSelect = (address) => {
    formik.setFieldValue("address", address);

    geocodeByAddress(address)
      .then((results) => {
        const addressComponents = results[0].address_components;
        let city, country, area;

        for (const component of addressComponents) {
          if (component.types.includes("locality")) {
            city = component.long_name;
          } else if (component.types.includes("country")) {
            country = component.long_name;
          } else if (component.types.includes("sublocality")) {
            area = component.long_name;
          }
        }

        if (city) {
          formik.setFieldValue("city", city);
        }

        if (country) {
          formik.setFieldValue("country", country);
        }

        if (area) {
          formik.setFieldValue("area", area);
        }

        getLatLng(results[0]).then((latLng) => {
          formik.setFieldValue("latitude", latLng.lat);
          formik.setFieldValue("longitude", latLng.lng);
        });
      })
      .catch((error) => console.error("Error", error));
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      name: "",
      address: "",
      area: "",
      city: "",
      country: "",
      firstName: "",
      lastName: "",
      phone: "",
      latitude: 0,
      longitude: 0,
      isUnlimited: 0,
      start_date: moment().format("YYYY-MM-DD"),
      end_date: moment().add(1, "year").format("YYYY-MM-DD"),
      remainingProject: 1
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);

      try {
        const formData = new FormData();

        Object.keys(values).forEach((key) => {
          formData.append(key, values[key]);
        });

        new Api()
          .post(APIS_END_POINTS.USER_SIGN_UP, {
            data: formData,
            returnError: true,
          })
          .then((response) => {
            localStorage.setItem("accessToken", response.data.accessToken);
            props.updateUserDetails(response.data);
            props.loginUser();
            props.history.push(URL_PROJECT_LISTING);
          });
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
  });

  const handleGeolocationError = () => {
    setLoading(false);
  };

  useEffect(() => {
    checkIfAlreadyLoggedIn();

    if (navigator.geolocation) {
      setLoading(true);
      navigator.geolocation.getCurrentPosition(async (position) => {
        const { latitude, longitude } = position.coords;
        await formik.setFieldValue("latitude", latitude);
        await formik.setFieldValue("longitude", longitude);

        try {
          const response = await fetch(
            `${process.env.REACT_APP_GOOGLE_MAP_API}?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`
          );

          if (response.ok) {
            const data = await response.json();
            const addressComponents = data.results[0]?.address_components || [];

            addressComponents.forEach((component) => {
              const type = component.types[0];
              const value = component.long_name;

              if (type === "route") {
                formik.setFieldValue("address", value);
              } else if (type === "locality") {
                formik.setFieldValue("city", value);
              } else if (type === "administrative_area_level_1") {
                formik.setFieldValue("area", value);
              } else if (type === "country") {
                formik.setFieldValue("country", value);
              }
            });
          }
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      }, handleGeolocationError);
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  return (
    <Loader
      className="loader-height"
      show={loading}
      message={<ProgressRipple />}
    >
      <MainWrapper>
        <SignUpWrapper>
          <div className="header flex">
            <img src={BirdIcon} alt="Bird Logo" width="234px" height="172px" />
            <div className="title"> Create an account</div>
          </div>
          <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
            {inputs.map((input) => (
              <div key={input.name}>
                {input.name === "address" ? (
                  <PlacesAutocomplete
                    value={formik.values.address}
                    onChange={handleAutocompleteChange}
                    onSelect={handleAutocompleteSelect}
                    label={input.label}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        <InputWithLabel
                          label={input.label}
                          {...getInputProps({
                            placeholder: "Search Address...",
                            className: "location-search-input",
                          })}
                          fullWidth
                          name="organizationAddress"
                          value={formik.values.address}
                          helperText={
                            formik.touched.address && formik.errors.address
                          }
                          error={
                            formik.touched.address && formik.errors.address
                          }
                        />
                        <div className="autocomplete-dropdown-container">
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion) => {
                            const className = suggestion.active
                              ? "suggestion-item--active"
                              : "suggestion-item";
                            const style = suggestion.active
                              ? {
                                  backgroundColor: "#fafafa",
                                  cursor: "pointer",
                                }
                              : {
                                  backgroundColor: "#ffffff",
                                  cursor: "pointer",
                                };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                ) : (
                  <InputWithLabel
                    className="full-width"
                    name={input.name}
                    placeholder={input.label}
                    label={input.label}
                    type={input.type}
                    value={formik.values[input.name]}
                    onChange={formik.handleChange}
                    validated={
                      formik.touched[input.name] && formik.errors[input.name]
                    }
                  />
                )}
              </div>
            ))}
            <div className="footer flex">
              <div className="register-btn">
                <Button
                  type="submit"
                  variant="outlined"
                  className="btn"
                  fullWidth
                >
                  Register
                </Button>
              </div>

              <div className="sign-up">
                <Link to={URL_LOGIN} variant="body2">
                  {"Already have an account? Sign In"}
                </Link>
              </div>
            </div>
          </form>
        </SignUpWrapper>
      </MainWrapper>
    </Loader>
  );
};

export default connect(mapReduxStateToProps, mapDispatchToProps)(SignUp);
