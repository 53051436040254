import firebase from 'firebase/app';
import 'firebase/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyA_jbGFGmMCYbLvQouRvuGtPToWabJv1X8",
    authDomain: "instantinput-efe90.firebaseapp.com",
    projectId: "instantinput-efe90",
    storageBucket: "instantinput-efe90.appspot.com",
    messagingSenderId: "499507947767",
    appId: "1:499507947767:web:3fcd5b8272813f9a358bf2",
    measurementId: "G-H8JXT899PE"
};

firebase.initializeApp(firebaseConfig);


export const getToken = (setTokenFound) => {
    let messaging;
    if (firebase.messaging.isSupported()) {

        messaging = firebase.messaging();
        return messaging.getToken({vapidKey: process.env.REACT_APP_FCM_VAPID_KEY})
            .then((currentToken) => {
                if (currentToken) {
                    // console.log('current token for client: ', currentToken);
                    // setTokenFound(true);
                    // Track the token -> client mapping, by sending to backend server
                    // show on the UI that permission is secured
                    localStorage.setItem("token", currentToken);
                } else {
                    // console.log('No registration token available. Request permission to generate one.');
                    // setTokenFound(false);
                    // shows on the UI that permission is required
                    localStorage.set("token", "");
                }
            }).catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
                // catch error while creating client token
            });
    }
}

export const onMessageListener = () =>
    new Promise((resolve) => {
        let messaging;
        if (firebase.messaging.isSupported()) {

            messaging = firebase.messaging();
        }
        messaging.onMessage((payload) => {
            resolve(payload);
        });
    });