import React, {useState} from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

// THEMES
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import { createMuiTheme } from '@material-ui/core/styles';

// USER DEFINED
import Website from "./Components/Website";
import { THEME_SETTINGS } from "./themeSettings";
import { UtilityStyles } from './Styles/Utils'
import { store } from "./Redux/Store";
import {getToken,onMessageListener} from "./firebase";

const theme = createMuiTheme(THEME_SETTINGS);

function App() {

	const [show, setShow] = useState(false);

	const [notification, setNotification] = useState({title: '', body: ''});
	const [isTokenFound, setTokenFound] = useState(false);

	getToken(setTokenFound);

	onMessageListener().then(payload => {
		// setShow(true);
		// setNotification({title: payload.notification.title, body: payload.notification.body})


		// console.log(payload);


		const notificationTitle = payload.data.title;
		const notificationOptions = {
			body: payload.data.message,
		};

		let notification=new Notification(notificationTitle,notificationOptions);


	}).catch(err => console.log('failed: ', err));

	return (
		<Provider store={store}>
			<BrowserRouter basename={process.env.REACT_APP_BASE_PATH}>
				<MuiThemeProvider theme={theme}>
					<Website />
					<UtilityStyles />
				</MuiThemeProvider>
			</BrowserRouter>
		</Provider>
	);
}

export default App;
