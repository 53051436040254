import { createGlobalStyle } from "styled-components";
import { FONTS, responsive } from "./Constants";

import PoppinsPrimary from "Assets/Fonts/BeVietnam/BeVietnam-Regular.ttf";
import PoppinsMedium from "Assets/Fonts/BeVietnam/BeVietnam-Medium.ttf";
import PoppinsBold from "Assets/Fonts/BeVietnam/BeVietnam-Bold.ttf";
import PoppinsSemiBold from "Assets/Fonts/BeVietnam/BeVietnam-SemiBold.ttf";
import PoppinsLight from "Assets/Fonts/BeVietnam/BeVietnam-Light.ttf";
import PoppinsThin from "Assets/Fonts/BeVietnam/BeVietnam-Thin.ttf";

export const UtilityStyles = createGlobalStyle`
    * {
        box-sizing  : border-box;
        outline     : none;
    }
    html,
    body {
        margin: 0;
        padding: 0;
    }
    body{
        background-color    : #fff;
        font-family         : ${FONTS.PRIMARY};
        line-height         : 1.2;
        min-height          : 100vh;
        position            : relative;
    }
    .full-height {
        height: 100%;
    }
    .full-width {
        width: 100%;
    }

    .flex {
        display: flex;
    }
    .f-column {
        flex-direction: column;
    }
    .f-v-center {
        align-items: center;
    }
    .f-h-center {
        justify-content: center;
    }
    .f-j-between {
        justify-content: space-between;
    }

    .disabled-button {
        color: #7BC8A9 !important;
        opacity: 50%;
    }

    .text-center {
        text-align: center;
    }

    .visible-md {
        display: none;
        ${responsive.TABLET`display:block`}
    }
     .hidden {
        display : none;
    }
    .hidden-md {
        ${responsive.TABLET`display:none`}
    }
    .plain-notification {
        background-color: #fff;
    }

    .elipsis {
        overflow        : hidden;
        text-overflow   : ellipsis;
        white-space     : nowrap;
    }
    
    .no-data-text {
        color       : #707070;
        font-size   : 15px;
        text-align  : center;
        width       : 100%;
    }
    
    .Loader {
        min-height : calc(100% - 60px);
    }
    
    .Loader .Loader__background{
        background-color : #00000017 !important;
    }

    .position-relative {
        position: relative;
    }

    @font-face {
        font-family: ${FONTS.PRIMARY};
        font-style: normal;
        font-weight: normal;
        src: local(${FONTS.PRIMARY}), url(${PoppinsPrimary}) format("truetype");
    }
    @font-face {
        font-family: ${FONTS.PRIMARY_MEDIUM};
        font-style: normal;
        font-weight: normal;
        src: local(${
          FONTS.PRIMARY_MEDIUM
        }), url(${PoppinsMedium}) format("truetype");
    }
    @font-face {
        font-family: ${FONTS.PRIMARY_BOLD};
        font-style: normal;
        font-weight: normal;
        src: local(${
          FONTS.PRIMARY_BOLD
        }), url(${PoppinsBold}) format("truetype");
    }
    @font-face {
        font-family: ${FONTS.PRIMARY_SEMI_BOLD};
        font-style: normal;
        font-weight: normal;
        src: local(${
          FONTS.PRIMARY_SEMI_BOLD
        }), url(${PoppinsSemiBold}) format("truetype");
    }
    @font-face {
        font-family: ${FONTS.PRIMARY_LIGHT};
        font-style: normal;
        font-weight: normal;
        src: local(${
          FONTS.PRIMARY_LIGHT
        }), url(${PoppinsLight}) format("truetype");
    }
    @font-face {
        font-family: ${FONTS.PRIMARY_THIN};
        font-style: normal;
        font-weight: normal;
        src: local(${
          FONTS.PRIMARY_THIN
        }), url(${PoppinsThin}) format("truetype");
    }
`;
