import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import Routes from "Routes/Route.jsx";
import ErrorBoundary from "./Commons/ErrorBoundary/ErrorBoundary";
import EventManager from "./EventManager/EventManager";
import TopBar from "./Commons/Topbar/Topbar";
import SideBar from "./Commons/SideBar/SideBar.jsx";
import {
  URL_FORGOT_PASSWORD,
  URL_LOGIN,
  URL_SIGN_UP,
  URL_TERMS_AND_CONDITION,
} from "Helpers/Paths";
import { ContentWrapper } from "./Website.style";
import { useSelector } from "react-redux";
import { RegisterQuillWrapper } from "Helpers/QuillWrapper";

function Website(props) {
  useEffect(() => {
    RegisterQuillWrapper();
  }, []);
  const settings = useSelector((state) => state.General.sidebar);

  const displayBlock = () => {
    const pathname = props.location.pathname;
    if (
      pathname === URL_LOGIN ||
      pathname === URL_SIGN_UP ||
      pathname === URL_TERMS_AND_CONDITION ||
      pathname === URL_FORGOT_PASSWORD
    ) {
      return false;
    }
    return true;
  };

  const isVisible = displayBlock();
  return (
    <ErrorBoundary>
      {isVisible && <TopBar />}
      {isVisible && <SideBar />}
      <ContentWrapper isVisible={isVisible} mini={settings.open}>
        <Routes />
      </ContentWrapper>
      <EventManager />
    </ErrorBoundary>
  );
}

export default withRouter(Website);
