import React from "react";

import BirdLogo from "Assets/Images/black-bird-without-logo.svg";
import { InputWrapper, TextField, ProgressWrapper } from "./Commons.style";

export function InputWithLabel(props) {
  let { label, ...rest } = props;
  return (
    <InputWrapper className="input-label-wrapper">
      <label className="label">{label}</label>
      <TextField {...rest} />
      <div className="error elipsis">{props.validated}</div>
    </InputWrapper>
  );
}

export function InputWithLabel1(props) {
  let { label, select, ...rest } = props;
  return (
    <InputWrapper className="input-label-wrapper">
      <div className={"label"}> {label} </div>
      <TextField {...rest} />
      <div className="error elipsis">{props.validated}</div>
    </InputWrapper>
  );
}

export function ProgressRipple({ animation }) {
  return (
    <ProgressWrapper className="flex" animation={animation}>
      <div className="img-wrapper flex f-v-center f-h-center">
        <img className="img" src={BirdLogo} alt="" />
      </div>

      <div className="ripple-wrapper">
        <div className="ripple blue"></div>
        <div className="ripple green"></div>
        <div className="ripple yellow"></div>
        <div className="ripple orange"></div>
        <div className="ripple red"></div>
      </div>
    </ProgressWrapper>
  );
}
