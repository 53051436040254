import LazyLoader from "@loadable/component";
import Login from "Components/Pages/Login/Login.jsx";

import {
  URL_PROJECT_LISTING,
  URL_CREATE_PROJECT,
  URL_CREATE_COMPANY,
  URL_PROJECT_DETAIL,
  URL_PROJECT_DETAIL_LOCATION,
  URL_PROJECT_DETAIL_DECISION,
  URL_PROJECT_DETAIL_TIMELINE,
  URL_PROJECT_DETAIL_CONTACTS,
  URL_PROJECT_MILESTONES,
  URL_PROJECT_DETAIL_OVERVIEW,
  URL_PROJECT_DETAIL_ADD_CONTACTS,
  URL_PROJECT_DETAIL_ADD_EVENT,
  // URL_PROJECT_DETAIL_ADD_QUESTION,
  URL_PROJECT_DETAIL_ADD_DOCUMENT,
  URL_PROJECT_DETAIL_EDIT_CONTACTS,
  URL_PROJECT_DETAIL_EDIT_EVENT,
  URL_USER_EDIT_PROFILE,
  URL_USER_ORGANIZATION_EDIT_PROFILE,
  URL_PROJECT_DETAIL_ADD_SURVEY,
  URL_PROJECT_DETAIL_EDIT_SURVEY,
  URL_PROJECT_MEMBERS_LISTING,
  URL_PROJECT_MEMBER_ADD,
  URL_PROJECT_DETAIL_IMAGES,
  URL_PROJECT_MEMBER_EDIT,
  URL_PRICING,
  URL_CHANGE_PASSWORD,
  URL_PRIVACY_POLICY,
  URL_EDIT_PROJECT,
  URL_EDIT_PROJECT_MEMBERS,
  URL_PROJECT_DETAIL_EVENT,
  URL_PROJECT_DETAIL_DOCUMENT,
  URL_PROJECT_DETAIL_NOTIFICATION,
  URL_PROJECT_DETAIL_SEND_NOTIFICATION,
  URL_PROJECT_DETAIL_SETUP_CHECKLIST,
  URL_FEEDBACK_ADD_FEEDBACK,
  URL_PROJECT_DETAIL_COMMENTS_LIST,
  URL_PROJECT_DETAIL_COMMENTS_SHOW,
  URL_PROJECT_DETAIL_SURVEY,
  URL_PROJECT_DETAIL_GRAPH_SURVEY,
  URL_PROJECT_DETAIL_SURVEY_USER,
  URL_PROJECT_PREVIEW,
  URL_NOTIFICATION,
  URL_COMPANY_LIST,
  URL_COMPANY_DETAILS,
  URL_COMMENTS,
  URL_COMMENT_REPLIES,
  URL_STATISTICS,
  URL_PROJECT_DETAIL_PREVIEW_SURVEY,
  URL_SIGN_UP,
  URL_PROJECT_DETAIL_NOTES_LIST,
} from "Helpers/Paths";

const routes = [
  {
    path: "/",
    exact: true,
    component: Login,
  },
  {
    path: URL_SIGN_UP,
    exact: true,
    component: LazyLoader(() => import("Components/Pages/SignUp/SignUp")),
  },
  {
    path: URL_CHANGE_PASSWORD,
    exact: true,
    component: LazyLoader(() =>
      import("Components/Pages/ChangePassword/ChangePassword")
    ),
  },
  {
    path: URL_PRIVACY_POLICY,
    exact: true,
    component: LazyLoader(() =>
      import("Components/Pages/StaticPage/PrivacyAndPolicy")
    ),
  },
  {
    path: URL_USER_EDIT_PROFILE,
    exact: true,
    component: LazyLoader(() => import("Components/Pages/Profile/EditProfile")),
  },
  {
    path: URL_USER_ORGANIZATION_EDIT_PROFILE,
    exact: true,
    component: LazyLoader(() =>
      import("Components/Pages/Profile/EditOrganization")
    ),
  },
  {
    path: URL_PROJECT_LISTING,
    exact: true,
    component: LazyLoader(() =>
      import("Components/Pages/Project/Listing/ProjectListing.jsx")
    ),
  },
  {
    path: URL_STATISTICS,
    exact: true,
    component: LazyLoader(() =>
      import("Components/Pages/Statistics/Statistics.jsx")
    ),
  },
  {
    path: URL_PROJECT_MEMBERS_LISTING,
    exact: true,
    component: LazyLoader(() =>
      import("Components/Pages/Project/Member/Members")
    ),
  },
  {
    path: URL_PROJECT_MEMBER_ADD,
    exact: true,
    component: LazyLoader(() =>
      import("Components/Pages/Project/Member/AddMember")
    ),
  },
  {
    path: URL_PROJECT_MEMBER_EDIT,
    exact: true,
    component: LazyLoader(() =>
      import("Components/Pages/Project/Member/EditMember")
    ),
  },
  {
    path: URL_PROJECT_DETAIL_IMAGES,
    exact: true,
    component: LazyLoader(() =>
      import("Components/Pages/Project/Detail/Images/ProjectImages")
    ),
  },
  {
    path: URL_CREATE_PROJECT,
    exact: true,
    component: LazyLoader(() =>
      import("Components/Pages/Project/CreateProject/CreateProject.jsx")
    ),
  },
  {
    path: URL_CREATE_COMPANY,
    exact: true,
    component: LazyLoader(() =>
      import("Components/Pages/Company/CreateCompany/CreateCompany")
    ),
  },
  {
    path: URL_NOTIFICATION,
    exact: true,
    component: LazyLoader(() =>
      import("Components/Pages/Notification/Notification")
    ),
  },
  {
    path: URL_COMMENTS,
    exact: true,
    component: LazyLoader(() => import("Components/Pages/Comments/Comments")),
  },
  {
    path: URL_COMMENT_REPLIES,
    exact: true,
    component: LazyLoader(() =>
      import("Components/Pages/Comments/CommentReplies")
    ),
  },
  {
    path: URL_EDIT_PROJECT + ":projectId",
    exact: true,
    component: LazyLoader(() =>
      import("Components/Pages/Project/CreateProject/EditProject")
    ),
  },
  {
    path: URL_EDIT_PROJECT_MEMBERS + ":projectId",
    exact: true,
    component: LazyLoader(() =>
      import("Components/Pages/Project/CreateProject/EditProjectMember")
    ),
  },
  {
    path: URL_PROJECT_DETAIL + ":projectId",
    exact: true,
    component: LazyLoader(() =>
      import("Components/Pages/Project/Detail/ProjectDetailChanges")
    ),
  },
  {
    path: URL_PROJECT_DETAIL_LOCATION,
    exact: true,
    component: LazyLoader(() =>
      import("Components/Pages/Project/Detail/Location/ProjectLocation.jsx")
    ),
  },
  {
    path: URL_PROJECT_DETAIL_DECISION,
    exact: true,
    component: LazyLoader(() =>
      import("Components/Pages/Project/Detail/Decision/ProjectDecision.jsx")
    ),
  },
  {
    path: URL_PROJECT_DETAIL_OVERVIEW,
    exact: true,
    component: LazyLoader(() =>
      import("Components/Pages/Project/Detail/Overview/ProjectOverView.jsx")
    ),
  },
  {
    path: URL_PROJECT_DETAIL_TIMELINE,
    exact: true,
    component: LazyLoader(() =>
      import("Components/Pages/Project/Detail/TimeLine/ProjectTimeLine.jsx")
    ),
  },
  {
    path: URL_PROJECT_DETAIL_CONTACTS + ":projectId",
    exact: true,
    component: LazyLoader(() =>
      import("Components/Pages/Project/Detail/Contacts/ProjectContact.jsx")
    ),
  },
  {
    path: URL_PROJECT_MILESTONES + ":projectId",
    exact: true,
    component: LazyLoader(() =>
      import("Components/Pages/Project/Detail/Milestones/Milestones.jsx")
    ),
  },
  {
    path: URL_PROJECT_DETAIL_ADD_CONTACTS + ":projectId",
    exact: true,
    component: LazyLoader(() =>
      import("Components/Pages/Project/Detail/Contacts/AddContact.jsx")
    ),
  },
  {
    path: URL_PROJECT_DETAIL_EDIT_CONTACTS + ":contactId",
    exact: true,
    component: LazyLoader(() =>
      import("Components/Pages/Project/Detail/Contacts/EditContact.jsx")
    ),
  },
  {
    path: URL_PROJECT_DETAIL_EVENT + ":projectId",
    exact: true,
    component: LazyLoader(() =>
      import("Components/Pages/Project/Detail/Events/Event.jsx")
    ),
  },
  {
    path: URL_PROJECT_DETAIL_ADD_EVENT + ":projectId",
    exact: true,
    component: LazyLoader(() =>
      import("Components/Pages/Project/Detail/Events/AddEvent.jsx")
    ),
  },
  {
    path: URL_PROJECT_DETAIL_EDIT_EVENT + ":eventId",
    exact: true,
    component: LazyLoader(() =>
      import("Components/Pages/Project/Detail/Events/EditEvent.jsx")
    ),
  },
  {
    path: URL_PROJECT_DETAIL_EDIT_SURVEY + ":surveyId/:projectId",
    exact: true,
    component: LazyLoader(() =>
      import("Components/Pages/Project/Detail/Question/AddQuestion.jsx")
    ),
  },
  {
    path: URL_PROJECT_DETAIL_PREVIEW_SURVEY + ":surveyId/:projectId",
    exact: true,
    component: LazyLoader(() =>
      import("Components/Pages/Project/Detail/Survey/SurveyPreview.jsx")
    ),
  },
  {
    path: URL_PROJECT_DETAIL_GRAPH_SURVEY + ":surveyId/:projectId",
    exact: true,
    component: LazyLoader(() =>
      import("Components/Pages/Project/Detail/Survey/SurveyGraphDetails")
    ),
  },
  {
    path: URL_PROJECT_DETAIL_ADD_SURVEY + ":projectId",
    exact: true,
    component: LazyLoader(() =>
      import("Components/Pages/Project/Detail/Question/AddSurvey")
    ),
  },
  {
    path: URL_PROJECT_DETAIL_SURVEY + ":projectId",
    exact: true,
    component: LazyLoader(() =>
      import("Components/Pages/Project/Detail/Survey/Survey")
    ),
  },
  {
    path:
      URL_PROJECT_DETAIL_SURVEY_USER +
      ":surveyId/:projectId/:type/:id/:isOther/:value",
    exact: true,
    component: LazyLoader(() =>
      import("Components/Pages/Project/Detail/Survey/SurveyAnswerDetails")
    ),
  },
  {
    path: URL_PROJECT_DETAIL_DOCUMENT + ":projectId",
    exact: true,
    component: LazyLoader(() =>
      import("Components/Pages/Project/Detail/Document/Document.jsx")
    ),
  },
  {
    path: URL_PRICING,
    exact: true,
    component: LazyLoader(() => import("Components/Pricing/Pricing")),
  },
  {
    path: URL_PROJECT_DETAIL_ADD_DOCUMENT + ":projectId",
    exact: true,
    component: LazyLoader(() =>
      import("Components/Pages/Project/Detail/Document/AddDocument.jsx")
    ),
  },
  {
    path: URL_PROJECT_DETAIL_COMMENTS_LIST + ":projectId",
    exact: true,
    component: LazyLoader(() =>
      import("Components/Pages/Project/Detail/Comments/Comments")
    ),
  },
  {
    path: URL_PROJECT_DETAIL_NOTES_LIST + ":projectId",
    exact: true,
    component: LazyLoader(() =>
      import("Components/Pages/Project/Detail/Notes/Notes")
    ),
  },
  {
    path: URL_PROJECT_DETAIL_COMMENTS_SHOW + ":projectId",
    exact: true,
    component: LazyLoader(() =>
      import("Components/Pages/Project/Detail/Comments/ShowComment")
    ),
  },
  {
    path: URL_PROJECT_DETAIL_NOTIFICATION + ":projectId",
    exact: true,
    component: LazyLoader(() =>
      import("Components/Pages/Project/Detail/Notifications/Notification")
    ),
  },
  {
    path: URL_PROJECT_DETAIL_SEND_NOTIFICATION + ":projectId",
    exact: true,
    component: LazyLoader(() =>
      import("Components/Pages/Project/Detail/Notifications/AddNotification")
    ),
  },
  {
    path: URL_PROJECT_DETAIL_SEND_NOTIFICATION + ":projectId",
    exact: true,
    component: LazyLoader(() =>
      import("Components/Pages/Project/Detail/Notifications/AddNotification")
    ),
  },
  {
    path: URL_PROJECT_DETAIL_SETUP_CHECKLIST + ":projectId",
    exact: true,
    component: LazyLoader(() =>
      import("Components/Pages/Project/Detail/CheckList/SetupCheckList")
    ),
  },
  {
    path: URL_FEEDBACK_ADD_FEEDBACK,
    exact: true,
    component: LazyLoader(() =>
      import(
        "Components/Pages/Feedback/Feedback" /* webpackChunkName: "AddDocument" */
      )
    ),
  },
  {
    path: URL_PROJECT_PREVIEW + "/:projectId",
    exact: true,
    component: LazyLoader(() =>
      import(
        "Components/Pages/Project/Detail/Preview/Preview" /* webpackChunkName: "AddDocument" */
      )
    ),
  },
  {
    path: URL_COMPANY_LIST,
    exact: true,
    component: LazyLoader(() =>
      import("Components/Pages/CompanyList/CompanyList")
    ),
  },
  {
    path: URL_COMPANY_DETAILS + ":companyId",
    exact: true,
    component: LazyLoader(() =>
      import("Components/Pages/CompanyDetails/CompanyDetails")
    ),
  },
];

export default routes;
