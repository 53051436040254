import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Button } from "@material-ui/core";
import { APIS_END_POINTS, URL_LOGIN } from "Helpers/Paths";
import { InputWithLabel } from "../../Commons/Commons";
import { Formik } from "formik";
import { generateValidationSchema } from "../../../Helpers/Utils";
import Api from "../../../Helpers/ApiHandler";
import { header } from "../../../Helpers/DefaultValues";
import BirdIcon from "Assets/Images/black-bird.svg";
import { MainWrapper, LoginWrapper } from "./Login.style";
import { FormWrapper } from "../SignUp/SignUp.style";

const inputs = [
  {
    label: "Email",
    name: "email",
    validation: { required: true, email: true },
  },
];

const inputsResetPassword = [
  {
    label: "Email",
    name: "email",
    validation: { required: true, email: true },
  },
  {
    label: "Password",
    name: "password",
    type: "password",
    validation: { required: true, min: 6 },
  },
  {
    label: "Confirm Password",
    name: "confirmPassword",
    type: "password",
    validation: { required: true, equals: "password" },
  },
  {
    label: "OTP",
    name: "otp",
    validation: { required: true, number: true, positive: true, integer: true },
  },
];

const initialValues = {
  email: "",
};

const initialValuesResetPassword = {
  email: "",
  password: "",
  confirmPassword: "",
  otp: "",
};

const validationSchema = generateValidationSchema(inputs);

const validationSchemaResetPassword =
  generateValidationSchema(inputsResetPassword);

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOTP: true,
    };
  }

  handleLogIn = (values) => {
    const data = {
      email: values.email,
    };
    new Api()
      .post(APIS_END_POINTS.COMPANY_FORGOT_PASSWORD, {
        data: data,
        headers: header,
      })
      .then((response) => {
        this.setState({
          isOTP: !this.state.isOTP,
        });
      })
      .catch((error) => {});
  };

  resetPassword = (values) => {
    const data = {
      email: values.email,
      otp: values.otp,
      password: values.password,
    };
    new Api()
      .put(APIS_END_POINTS.COMPANY_RESET_PASSWORD, { data })
      .then((response) => {
        this.props.history.push(URL_LOGIN);
        this.setState({
          isOTP: !this.state.isOTP,
        });
      })
      .catch((error) => {});
  };

  render() {
    return (
      <MainWrapper>
        <LoginWrapper>
          <div className="header flex">
            <img src={BirdIcon} alt="Bird Logo" />
            <div className="title">
              {this.state.isOTP ? "Forgot Password" : "Reset Password"}
            </div>
          </div>

          <Formik
            initialValues={
              this.state.isOTP ? initialValuesResetPassword : initialValues
            }
            onSubmit={this.state.isOTP ? this.handleLogIn : this.resetPassword}
            validationSchema={
              this.state.isOTP
                ? validationSchema
                : validationSchemaResetPassword
            }
          >
            {({ values, touched, errors, handleChange }) => (
              <FormWrapper>
                {this.state.isOTP
                  ? inputs.map((input) => (
                      <InputWithLabel
                        className="full-width"
                        name={input.name}
                        placeholder={input.label}
                        label={input.label}
                        type={input.type}
                        value={values[input.name]}
                        onChange={handleChange}
                        validated={touched[input.name] && errors[input.name]}
                      />
                    ))
                  : inputsResetPassword.map((input) => (
                      <InputWithLabel
                        className="full-width"
                        name={input.name}
                        placeholder={input.label}
                        label={input.label}
                        type={input.type}
                        value={values[input.name]}
                        onChange={handleChange}
                        validated={touched[input.name] && errors[input.name]}
                      />
                    ))}

                <div className="footer flex">
                  <div
                    className="f-password"
                    onClick={() => {
                      if (this.state.isOTP) {
                        this.setState({ isOTP: false });
                      } else {
                        // this.sendOTP();
                        this.setState({ isOTP: true });
                      }
                    }}
                  >
                    <Link variant="body2">
                      {this.state.isOTP
                        ? "Already have an otp?"
                        : "Resend OTP?"}
                    </Link>
                  </div>

                  <div className="login-btn">
                    <Button
                      type="submit"
                      variant="outlined"
                      className="btn"
                      fullWidth
                    >
                      Submit
                    </Button>
                  </div>

                  <div className="f-password">
                    <Link to={URL_LOGIN} variant="body2">
                      {"Already have an account? Sign In"}
                    </Link>
                  </div>
                </div>
              </FormWrapper>
            )}
          </Formik>
        </LoginWrapper>
      </MainWrapper>
    );
  }
}

export default withRouter(ForgotPassword);
