export const URL_LOGIN = "/login";
export const URL_SIGN_UP = "/signup";
export const URL_TERMS_AND_CONDITION = "/terms-condition";
export const URL_CHANGE_PASSWORD = "/change-password";
export const URL_FORGOT_PASSWORD = "/forgot-password";
export const URL_PRIVACY_POLICY = "/privacy-policy";
export const URL_USER_EDIT_PROFILE = "/profile/edit";
export const URL_USER_ORGANIZATION_EDIT_PROFILE = "/profile/organization/edit";
export const URL_PROJECT_LISTING = "/projects";
export const URL_CREATE_PROJECT = "/create-project";
export const URL_CREATE_COMPANY = "/create-company";
export const URL_STATISTICS = "/statistics";
export const URL_NOTIFICATION = "/notification";
export const URL_COMMENTS = "/comments";
export const URL_COMMENT_REPLIES = "/comment-replies";
export const URL_COMPANY_LIST = "/company-list";
export const URL_COMPANY_DETAILS = "/company/details/";

//member
export const URL_PROJECT_MEMBERS_LISTING = "/members";
export const URL_PROJECT_MEMBER_ADD = "/member/add";
export const URL_PROJECT_MEMBER_EDIT = "/member/edit";

//project detail screen
export const URL_EDIT_PROJECT = "/edit-project/";
export const URL_EDIT_PROJECT_MEMBERS = "/project/member/edit/";
export const URL_PROJECT_DETAIL = "/project/details/";
export const URL_PROJECT_DETAIL_IMAGES = "/project/images";
export const URL_PROJECT_DETAIL_LOCATION = "/project/location";
export const URL_PROJECT_DETAIL_DECISION = "/project/decision";
export const URL_PROJECT_DETAIL_OVERVIEW = "/project/overview";
export const URL_PROJECT_DETAIL_TIMELINE = "/project/timeline";
export const URL_PROJECT_DETAIL_CONTACTS = "/project/contacts/";
export const URL_PROJECT_DETAIL_ADD_CONTACTS = "/project/contact/add/";
export const URL_PROJECT_DETAIL_EDIT_CONTACTS = "/project/contact/edit/";
export const URL_PROJECT_DETAIL_EVENT = "/project/events/";
export const URL_PROJECT_DETAIL_ADD_EVENT = "/project/events/add/";
export const URL_PROJECT_DETAIL_EDIT_EVENT = "/project/events/edit/";
export const URL_PROJECT_DETAIL_EDIT_SURVEY = "/project/survey/edit/";
export const URL_PROJECT_DETAIL_PREVIEW_SURVEY = "/project/survey/preview/";
export const URL_PROJECT_DETAIL_GRAPH_SURVEY = "/project/survey/graph/";
export const URL_PROJECT_DETAIL_ADD_SURVEY = "/project/survey/add/";
export const URL_PRICING = "/pricing";
export const URL_PROJECT_DETAIL_DOCUMENT = "/project/documents/";
export const URL_PROJECT_DETAIL_ADD_DOCUMENT = "/project/document/add/";
export const URL_PROJECT_DETAIL_COMMENTS_LIST = "/project/comments/";
export const URL_PROJECT_DETAIL_NOTES_LIST = "/project/notes/";
export const URL_PROJECT_DETAIL_COMMENTS_SHOW = "/project/comments/details/";
export const URL_PROJECT_DETAIL_COMMENTS = "/project/comments/";
export const URL_PROJECT_DETAIL_NOTIFICATION = "/project/notification/";
export const URL_PROJECT_DETAIL_SEND_NOTIFICATION =
  "/project/notification/send/";
export const URL_PROJECT_DETAIL_SETUP_CHECKLIST = "/project/details/checklist/";
export const URL_FEEDBACK_ADD_FEEDBACK = "/feedback/add";
export const URL_PROJECT_DETAIL_SURVEY = "/project/survey/detail/";
export const URL_PROJECT_DETAIL_SURVEY_USER = "/project/survey/detail/users/";
export const URL_PROJECT_PREVIEW = "/project/preview";
export const URL_PROJECT_MILESTONES = "/project/milestones/";

export const APIS_END_POINTS = {
  //user
  USER_SIGN_UP: "company/signup", //company signup
  USER_SIGN_IN: "company/login", //company sign in
  USER_PROFILE_DATA_GET: "users/profile", //get data
  USER_EDIT: "member/", //Edit Information
  GET_MEMBER_DETAILS: "member/", //GET member details
  USER_ORGANIZATION_PROFILE_EDIT: "company/edit", //Edit Information
  GET_COMPANY_DETAILS: "company/", //GET Company Details
  COMPANY_LOGOUT: "company/logout", //POST company logout
  COMPANY_CHANGE_PASSWORD: "member/change-password", //change password
  COMPANY_FORGOT_PASSWORD: "member/forgot-password", //Forgot Password //POST
  COMPANY_RESET_PASSWORD: "member/reset-password", //Reset Password //Put
  ZENDESK_API: "zendesk-api/sign-in", //Forgot Password //POST

  //company
  POST_COMPANY_DETAILS: "company/details", //POST Company
  GET_COMPANY_ENTITY_TYPES: "company-entity", //GET Company entity types
  GET_COMPANY_LIST_NEW: "company/lists", //GET Company list
  GET_COMPANY_LIST: "company", //POST
  CHANGE_COMPANY_DETAILS: "company/details/", //PUT

  GET_PROJECT_LIST: "project/list", //get project list with pagination

  GET_ALL_TYPES_OF_PROJECTS_LIST: "interest/list", //get all projects types list
  GET_ALL_TYPES_OF_EVENT_INTEREST_LIST: "event-interest/list", //get event interest list
  GET_ALL_PROJECT_PHASES: "project-phases/list", //get all project phases

  //projects
  ADD_PROJECT: "project/", //Add Company project
  EDIT_PROJECTS: "project", //Edit Project
  GET_PROJECT_DETAILS: "project/", //project details fetch by ID //Get
  GET_PROJECT_MEMBERS: "project/members", //Project member details
  DELETE_PROJECT: "project", //  Delete Project by project id
  ADD_FAVOURITES_PROJECT: "project/members-favorite-project", //POST
  CHANGE_PROJECT_MEMBER_ROLE: "project-member/change-role", // Change role of the project member // POST
  GET_PROJECT_TIMELINES: "timeline/",
  EDIT_PROJECT_TIMELINES: "timeline/",
  DELETE_PROJECT_TIMELINES: "timeline/",
  GET_PROJECTS_ANALYTICS: "project/list/project-analytics",

  //contact
  GET_PROJECT_CONTACTS_LIST: "project-contacts/list/", //project contact list fetch by ID //Get
  ADD_CONTACTS: "project-contacts/", //POST
  EDIT_CONTACTS: "project-contacts/", //PUT
  DELETE_CONTACTS: "project-contacts/", //DELETE
  //event
  ADD_EVENTS: "project-events/", //POST //add event
  EDIT_EVENTS: "project-events/", //POST //add event
  GET_EVENT_LIST_PROJECT_ID: "project-events/events-by-project/", //Get Event List
  GET_EVENT_DETAILS_BY_ID: "project-events/", //get event details
  DELETE_EVENTS: "project-events", //DELETE

  //survey
  GET_SURVEY_LIST: "survey/list", //GET
  ADD_SURVEY_BY_PROJECT_ID: "survey", //POST
  GET_SURVEY_DETAILS_BY_ID: "survey/", //GET
  EDIT_SURVEY_BY_ID: "survey/", //PUT
  DELETE_SURVEY_BY_ID: "survey/", //DELETE

  //survey->question
  ADD_QUESTION_BY_SURVEY_ID: "survey/question", //POST
  EDIT_QUESTION_BY_QUESTION_ID: "survey/question/", //PUT
  DELETE_SURVEY_QUESTION_BY_ID: "survey/question/", //DELETE
  ADD_OPTION_QUESTION_BY_ID: "survey/question/option", //POST
  DELETE_OPTION_BY_ID: "survey/question/option/", //DELETE
  UPDATE_OPTION_BY_ID: "survey/question/option/", //PUT
  ADD_SURVEY_QUESTION_IMAGE: "survey/question/image", //POST
  DELETE_SURVEY_QUESTION_IMAGE: "survey/question/image/", //DELETE
  UPDATE_QUESTION_ORDER: "survey/question/", //PUT
  UPDATE_QUESTION_OPTION_ORDER: "survey/question/option/", //PUT

  //Survey Analytic
  GET_SURVEY_ALL_USERS_DETAILS_BY_SURVEY_ID: "survey/report/", //GET Download Survey Details by survey id
  GET_SURVEY_ANALYTIC_USER_DETAILS_BY_OPTION_ID: "survey/answer/users/",
  GET_SURVEY_ANALYTIC_USER_DETAILS_BY_QUESTION_ID:
    "survey/answer/users/by/question/",

  //Roles
  GET_ALL_MEMBERS_ROLES: "project/get-roles", //GET

  //Members
  ADD_MEMBER: "member", //POST
  GET_ALL_MEMBER_LIST: "member/list", //POST
  EDIT_MEMBER: "member/", //PUT
  DELETE_MEMBER: "member", //DELETE
  //locations
  ADD_LOCATION_BY_PROJECT_ID: "project/add-location", //POST
  GET_LOCATION_BY_PROJECT_ID: "project/get-location/", //GET
  EDIT_LOCATION_BY_PROJECT_ID: "project/edit-location", //PUT
  ADD_LOCATION_FILE_PROJECT_ID: "project/location/", //POST

  //comments
  GET_PROJECT_COMMENTS_BY_ID: "project-comments/list", //POST
  UPDATE_PROJECT_COMMENTS_BY_ID: "project-comments", //PUT
  GET_PROJECT_COMMENT_REPLIES_BY_ID: "project-comments/list/reply", //POST
  UPDATE_PROJECT_COMMENT_REPLIES_BY_ID: "project-comments/reply", //PUT

  //notes
  GET_PROJECT_NOTES_BY_ID: "project/get-notes", //POST

  //Static Page
  GET_TERMS_AND_CONDITION: "pages/web/terms-conditions-authority", //GET
  GET_PRIVACY_POLICY: "pages/web/privacy-policy-authority", //GET

  //ZenDesk create ticket API

  //notifications
  GET_PROJECT_NOTIFICATIONS: "notifications/user-notifications", //POST
  SEND_PROJECT_NOTIFICATION: "notifications/send-general-notification", //POST

  //Project Analytics
  GET_PROJECT_ANALYTICS_BY_PROJECT_ID: "project/project-analytics", //POST

  CREATE_TICKET_API: "https://openxcellinc.zendesk.com/api/v2/tickets.json",

  //Company members not in project member list
  GET_MEMBER_NOT_IN_PROJECT: "member/list-notIn-project", //POST
  GET_PROJECTS_CREATED_BY_MEMBER: "project/list/created-by-member", //POST
  CHANGE_PROJECT_OWNER: "project/change-owner", //POST
};
