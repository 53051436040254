import styled from "styled-components";
import { DIMENSIONS } from "Styles/Constants";
import { Dialog } from "@material-ui/core";

export const DialogWrapper = styled(Dialog)`
  .modal-root {
    border-radius: 10px;
  }
`;

export const ContentWrapper = styled.div`
  ${({ isVisible }) =>
    isVisible &&
    `
        padding         : 70px 18px 18px;
        margin-left     : auto;
        height          : 100vh;
    `}
  width           : ${(props) =>
    props.isVisible
      ? !props.mini
        ? `calc(100% - ${DIMENSIONS.SIDEBAR.MINI}px)`
        : `calc(100% - ${DIMENSIONS.SIDEBAR.FULL}px)`
      : "100%"}
`;
