import React, { memo } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import { SnackbarContentWrapper } from "./Toast.style";

function Toast(props) {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      autoHideDuration={props.duration || 3000}
      open={props.open}
      onClose={props.handleClose}
    >
      <SnackbarContentWrapper
        className={"themed"}
        aria-describedby="client-snackbar"
        classes={{
          message: "notification-message",
        }}
        message={<>{props.message}</>}
      />
    </Snackbar>
  );
}

export default memo(Toast);
