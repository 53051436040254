// CORE
import React from "react";
import { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { updateUserDetails, logOutUser } from "Redux/Auth/Actions";
import { hideToast } from "../../Redux/General/Actions";
import Toast from "../Commons/Toast/Toast.jsx";
import { APIS_END_POINTS, URL_LOGIN } from "../../Helpers/Paths";

//API
import Api from "Helpers/ApiHandler";
import { header } from "../../Helpers/DefaultValues";

class EventManager extends Component {
  componentDidMount() {
    // console.log(this.props);
    // if (!this.props.subscriptionData) {
    //     this.props.history.push(URL_PRICING)
    // }
  }

  render() {
    return (
      <React.Fragment>
        <Toast
          open={this.props.toast.enable}
          message={this.props.toast.message}
          handleClose={this.props.hideToast}
        />
        {this.props.isLoggedIn &&
          this.props.location.pathname !== URL_LOGIN &&
          this.props.location.pathname !== "/" && (
            <AfterLoginEvents userDetails={this.props.userDetails} />
          )}
      </React.Fragment>
    );
  }
}

class AfterLoginEvents extends React.Component {
  fetchProfileData = () => {
    const userDetails = this.props.userDetails;
    new Api()
      .get(APIS_END_POINTS.GET_MEMBER_DETAILS + userDetails.id, {
        headers: header,
      })
      .then((response) => {
        this.props.updateUserDetails(response.data);
      })
      .catch((error) => {});
  };

  async componentDidMount() {
    this.fetchProfileData();
  }

  render() {
    return <React.Fragment></React.Fragment>;
  }
}

const mapReduxStateToProps = (state) => {
  return {
    toast: state.General.toast,
    isLoggedIn: state.Auth.isLoggedIn,
    userDetails: state.Auth.userDetails,
  };
};

export default connect(mapReduxStateToProps, {
  hideToast,
  updateUserDetails,
  logOutUser,
})(withRouter(EventManager));
