export const ACTION_TYPES = {
  LOGIN_USER: "LOGIN_USER",
  LOGOUT_USER: "LOGOUT_USER",
  UPDATE_USER_DETAILS: "UPDATE_USER_DETAILS",
};

export const loginUser = (data) => {
  localStorage.setItem("isLoggedIn", true);
  return {
    type: ACTION_TYPES.LOGIN_USER,
    ...data,
  };
};

export const updateUserDetails = (data) => {
  localStorage.setItem("details", JSON.stringify(data));
  return {
    type: ACTION_TYPES.UPDATE_USER_DETAILS,
    data,
  };
};

export const logOutUser = () => {
  localStorage.clear();
  return {
    type: ACTION_TYPES.LOGOUT_USER,
  };
};
