import React from "react";
import { Switch, Route } from "react-router-dom";

// COMPONENTS
import Login from "Components/Pages/Login/Login.jsx";
import SignUp from "Components/Pages/SignUp/SignUp.jsx";
import TermsAndCondition from "Components/Pages/StaticPage/TermsAndCondition";
import ForgotPassword from "../Components/Pages/Login/ForgotPassword";

// ROUTING COMPONENTS
import ProtectedRoute from "./ProtectedRoute";
import RoutesList from "./RoutesList";
import {
  URL_FORGOT_PASSWORD,
  URL_LOGIN,
  URL_SIGN_UP,
  URL_TERMS_AND_CONDITION,
} from "Helpers/Paths";

function Routes() {
  return (
    <Switch>
      <Route path={URL_LOGIN} component={Login} />
      <Route path={URL_SIGN_UP} component={SignUp} />
      <Route path={URL_TERMS_AND_CONDITION} component={TermsAndCondition} />
      <Route path={URL_FORGOT_PASSWORD} component={ForgotPassword} />
      <ProtectedRoute>
        {RoutesList.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            component={route.component}
          />
        ))}
      </ProtectedRoute>
    </Switch>
  );
}

export default Routes;
