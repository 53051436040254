export const Messages = {
    validation: {
        email: {
            valid: "Ex user@mail.com",
            required: "Please enter your email address."
        },
        firstName: {
            required: "Please enter your first name."
        },
        lastName: {
            required: "Please enter your last name."
        },
        contactName: {
            required: "Please enter contact name."
        },
        eventName: {
            required: "Please enter event name."
        },
        eventType: {
            required: "Please enter event type."
        },
        organizationName: {
            required: "Please enter your organization name."
        },
        organizationAddress: {
            required: "Please enter your organization address."
        },
        organizationPhoneNumber: {
            required: "Please enter your organization Phone Number.",
            number: "Must be numbers only",
            positive: "Should be in Positive",
            integer: "Type without . (dots)",
            valid: "Please enter valid Phone Number."
        },
        otp: {
            required: "Please enter your OTP.",
            number: "Must be numbers only",
            positive: "Should be in Positive",
            integer: "Type without . (dots)"
        },
        organizationLat: {
            required: "Please enter your valid organization Address."
        },
        organizationLng: {
            required: "Please enter your valid organization Address."
        },
        phoneNumber: {
            required: "Please enter your phone number.",
            number: "Must be numbers only",
            positive: "Should be in Positive",
            integer: "Type without . (dots)",
            valid: "Please enter valid phone number"
        },
        password: {
            required: "Please enter your password",
            // eslint-disable-next-line
            min: "Password must be atleast ${min} length"
        },
        currentPassword: {
            required: "Please enter your current password",
            // eslint-disable-next-line
            min: "Password must be atleast ${min} length"
        },
        confirmPassword: {
            required: "Please retype your password",
            equals: "Password doesn't match"
        },
        projectName: {
            required: "Please enter project name"
        },
        subject: {
            required: "Please enter subject name"
        },
        message: {
            required: "Please enter message name"
        }
    }
}
