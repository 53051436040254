import { css } from "styled-components";

// COLOR PALLET
export const COLORS = {
  // Black
  SECONDARY: "#27283A",
  SECONDARY_LIGHT: "#212333",

  //Except black
  PRIMARY_EXTRA_LIGHT: "#FFFDF8",
  PRIMARY_LIGHT: "#FAF6EF",
  PRIMARY: "#7BC8A9",
  TABLE_BACKGROUND: "#FFFFFF",

  // E9DFA7
  PRIMARY_DARK: "#DDD39A",

  PRIMARY_BORDER_COLOR: "#E0DDD7",
  PRIMARY_BOX_SHADOW_COLOR: "#E9DFA780",
  HEADER_TEXT_COLOR: "#F9F5EE",

  ORANGE_DARK: "#f78035",
  ORANGE_LIGHT: "#FAA637",

  //CYAN
  TERTIARY: "#3A82A7",
  TERTIARY_LIGHT: "#92abbd",

  //RED
  DANGER: "#d32f2f",

  GREY_TEXT_COLOR: "#707070",
  GREY_LIGHT_COLOR: "#C1C1C1",

  BLUE: "#4495A9",
};

// BREAKPOINTS
export const BREAKPOINTS_VALUE = {
  DISPLAY: 1750,
  DESKTOP: 1450, // x_large
  LAPTOP: 1278, // large
  TABLET: 960, // medium
  PHABLET: 600, // small
  MOBILE: 450, // x_small
};
export const BREAKPOINTS = {
  A_DESKTOP: `${BREAKPOINTS_VALUE.DESKTOP}px`,
  A_LAPTOP: `${BREAKPOINTS_VALUE.LAPTOP}px`,
  A_TABLET: `${BREAKPOINTS_VALUE.TABLET}px`,
  A_PHABLET: `${BREAKPOINTS_VALUE.PHABLET}px`,
  A_MOBILE: `${BREAKPOINTS_VALUE.MOBILE}px`,

  DISPLAY: `${BREAKPOINTS_VALUE.DISPLAY - 1}px`,
  DESKTOP: `${BREAKPOINTS_VALUE.DESKTOP - 1}px`, // x_large
  LAPTOP: `${BREAKPOINTS_VALUE.LAPTOP - 1}px`, // large
  TABLET: `${BREAKPOINTS_VALUE.TABLET - 1}px`, // medium
  PHABLET: `${BREAKPOINTS_VALUE.PHABLET - 1}px`, // small
  MOBILE: `${BREAKPOINTS_VALUE.MOBILE - 1}px`, // x_small
};

//Fonts

export const FONTS = {
  PRIMARY: "Be Vietnam",
  PRIMARY_BOLD: "Be Vietnam Bold",
  PRIMARY_SEMI_BOLD: "Be Vietnam Bold",
  PRIMARY_LIGHT: "Be Vietnam Light",
  PRIMARY_MEDIUM: "Be Vietnam Medium",
  PRIMARY_ITALIC: "Be Vietnam Italic",
  PRIMARY_THIN: "Be Vietnam Thin",

  SECONDARY: "PolarisCondensed-Book",
  SECONDARY_MEDIUM: "PolarisCondensed-Medium",
  SECONDARY_HEAVY: "PolarisCondensed-Heavy",
};

export const responsive = Object.keys(BREAKPOINTS).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${BREAKPOINTS[label]}) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});

export const DIMENSIONS = {
  HEADER: 64,
  SIDEBAR: {
    FULL: 313,
    MINI: 70,
  },
};
