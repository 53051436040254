import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import BusinessIcon from "@material-ui/icons/Business";
import FeedbackIcon from "@material-ui/icons/Feedback";
import NotificationsIcon from "@material-ui/icons/Notifications";
import CommentIcon from "@material-ui/icons/Comment";
import SecurityIcon from "@material-ui/icons/Security";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import LockIcon from "@material-ui/icons/Lock";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import BarChartIcon from "@material-ui/icons/BarChart";

export const icons = {
  "your-projects": <FormatListBulletedIcon />,
  "add-members": <PersonAddIcon />,
  "add-organization": <GroupAddIcon />,
  "organizations-list": <BusinessIcon />,
  feedback: <FeedbackIcon />,
  notifications: <NotificationsIcon />,
  comments: <CommentIcon />,
  "privacy-policy": <SecurityIcon />,
  profile: <AccountCircleIcon />,
  "change-password": <LockIcon />,
  logout: <ExitToAppIcon />,
  statistics: <BarChartIcon />,
};
