import { useState, useEffect, useCallback } from "react";
import { withRouter } from "react-router-dom";
import { debounce } from "lodash-es";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  AppBar,
  InputBase,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import {
  projectOwner,
  toggleView,
  projectInterestId,
  projectSearch,
  changeCompany,
} from "Redux/General/Actions";
import { fetchCompanySubscription } from "Redux/Subscription/Actions";
import Api from "Helpers/ApiHandler";
import { URL_PROJECT_LISTING, APIS_END_POINTS } from "Helpers/Paths";
import { header } from "Helpers/DefaultValues";
import SearchIcon from "Assets/Images/search.svg";
import menu_grid from "Assets/Images/Menu_Grid.svg";
import menu_list from "Assets/Images/Menu_List.svg";
import { MainWrapper, ToolbarWrapper } from "./Topbar.style";

const ListingPage = [URL_PROJECT_LISTING];

const mapReduxStateToProps = (state) => ({
  projectView: state.General.projectView,
  projectOwnerType: state.General.projectOwnerType,
  projectInterestId: state.General.projectInterestId,
  isLoggedIn: state.Auth.isLoggedIn,
  projectSearch: state.General.projectSearch,
});

function Topbar(props) {
  const { isLoggedIn } = props;
  const [projectTypes, setProjectTypes] = useState([]);
  const settings = useSelector((state) => state.General.sidebar);
  const userInfo = useSelector((state) => state.Auth.userDetails);
  const dispatch = useDispatch();
  const [companyList, setCompanyList] = useState([]);
  const [comapnyId, setCompanyId] = useState(null);

  const debouncedSearch = useCallback(
    debounce((value) => {
      props.dispatch(projectSearch(value));
    }, 1000),
    []
  );

  const handleChange = (event, type) => {
    if (type === "project_value") {
    } else if (type === "organize") {
      props.dispatch(projectOwner(event.target.value));
    } else if (type === "project_type") {
      props.dispatch(projectInterestId(event.target.value));
    } else if (type === "search_project") {
      debouncedSearch(event.target.value);
    } else if (type === "company") {
    }
  };

  const handleChangeCompany = (_, value) => {
    setCompanyId(value);
    props.dispatch(changeCompany(value.id));
    props.history.push(URL_PROJECT_LISTING);
  };

  useEffect(() => {
    if (isLoggedIn) {
      fetchProjectInterest();
    }
    if (userInfo && userInfo.roleType !== "SUPERADMIN")
      void fetchCompanyCurrentSubscription();
  }, [isLoggedIn]);

  const fetchProjectInterest = () => {
    const data = {
      perPage: 50,
    };
    new Api()
      .post(APIS_END_POINTS.GET_ALL_TYPES_OF_PROJECTS_LIST, {
        data: data,
        headers: header,
      })
      .then((response) => {
        setProjectTypes(response.data.list);
      })
      .catch(() => {});
  };

  const fetchCompanyCurrentSubscription = async () => {
    const subscription = await new Api().get(
      APIS_END_POINTS.GET_COMPANY_DETAILS + userInfo.companyId,
      { headers: header }
    );

    if (subscription.data.isFreeTire && subscription.data.isPaymentDone) {
      dispatch(
        fetchCompanySubscription({ isPaymentDone: true, isFreeTire: true })
      );
    } else if (subscription.data.company_subscription_transaction) {
      dispatch(
        fetchCompanySubscription(
          subscription.data.company_subscription_transaction
        )
      );
    }
  };

  useEffect(() => {
    if (userInfo && userInfo.roleType === "SUPERADMIN") {
      fetchCompanyList();
    }
  }, []);

  const fetchCompanyList = () => {
    const data = { sort: { field: "name", order: "ASC" } };

    new Api()
      .post(APIS_END_POINTS.GET_COMPANY_LIST, { data: data, headers: header })
      .then((response) => {
        setCompanyList(response.data.rows);
        if (response.data.rows) {
          const list = response.data.rows;
          if (list.length && list.length > 0) {
            setCompanyId(list[0]);
            props.dispatch(changeCompany(list[0].id));
          }
        }
      });
  };
  const pathName = props.location.pathname;

  return (
    <MainWrapper>
      <AppBar className={`app-bar ${!settings.open && "mini"}`}>
        <ToolbarWrapper className="justify-content-f-e">
          {userInfo && userInfo.roleType === "SUPERADMIN" && (
            <div className="dropdown-section">
              <Autocomplete
                disablePortal
                disableClearable
                value={comapnyId}
                options={companyList}
                className="company-dropdown"
                onChange={handleChangeCompany}
                getOptionLabel={(option) => option?.name}
                disabled={!ListingPage.includes(pathName)}
                getOptionSelected={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Company" />
                )}
              />
            </div>
          )}
          {ListingPage.includes(pathName) && (
            <>
              {userInfo && userInfo.roleType !== "SUPERADMIN" && (
                <div className="dropdown-section">
                  <Select
                    value={props.projectOwnerType}
                    onChange={(e) => handleChange(e, "organize")}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    classes={{
                      select: "selected-text",
                    }}
                  >
                    <MenuItem value={"OrganizationProject"}>
                      {" "}
                      Organization Projects{" "}
                    </MenuItem>
                    <MenuItem value={"MyProject"}> My Projects </MenuItem>
                  </Select>
                </div>
              )}

              <div className="dropdown-section">
                <Select
                  value={props.projectInterestId}
                  onChange={(e) => handleChange(e, "project_type")}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  classes={{
                    select: "selected-text",
                  }}
                >
                  <MenuItem value="">Project Type</MenuItem>
                  {projectTypes.map((projectType) => (
                    <MenuItem key={projectType.id} value={projectType.id}>
                      {projectType.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>

              <div className="dropdown-section">
                <InputBase
                  id="search_projects"
                  type="search"
                  placeholder="Search Projects"
                  className="search-text-base"
                  endAdornment={<img src={SearchIcon} alt="Search" />}
                  onChange={(e) => handleChange(e, "search_project")}
                />
              </div>

              <div
                className="menu-logo"
                onClick={() => props.dispatch(toggleView())}
              >
                <img
                  src={props.projectView === "LIST" ? menu_grid : menu_list}
                  alt="Change View"
                />
              </div>
            </>
          )}
        </ToolbarWrapper>
      </AppBar>
    </MainWrapper>
  );
}

export default connect(mapReduxStateToProps, null)(withRouter(Topbar));
