import styled from "styled-components";
import { Toolbar } from '@material-ui/core';
import Menu from "Assets/Images/Menu_List.svg"

import { COLORS, DIMENSIONS, FONTS } from "Styles/Constants"

export const MainWrapper = styled.div`
    .app-bar {    
        width               : calc(100% - ${DIMENSIONS.SIDEBAR.FULL}px);
        position            : fixed;
        box-shadow          : 0 0px 5px 0px ${COLORS.PRIMARY_BOX_SHADOW_COLOR};
        background-color    : ${COLORS.HEADER_TEXT_COLOR};

        &.mini {
            width  : calc(100% - ${DIMENSIONS.SIDEBAR.MINI}px);
        }
    }

    .justify-content-s-b {
        justify-content     : space-between;
    }

    .justify-content-f-e {
        justify-content     : flex-end;
    }
`

export const ToolbarWrapper = styled(Toolbar)`
    color               : ${COLORS.SECONDARY};
    background-color    : ${COLORS.HEADER_TEXT_COLOR};

    .menu {
        background-image : url(${Menu});
        width : 30px;
        height : 30px;
        background-size: 100%;
        background-repeat: no-repeat;
        background-position : center;

        &:hover {
            cursor: pointer;
        }
    }
    .title {
        font-family         : ${FONTS.PRIMARY_BOLD};
        font-size           : 30px;
        padding             : 5px;
    }

    .MuiInput-underline:before {
        border-bottom       : 2px solid ${COLORS.SECONDARY};
    }

    .dropdown-section {
        margin-right    : 30px;
    }

    .company-dropdown {
        width: 200px;
    }

    .selected-text {
        font-family         : ${FONTS.PRIMARY_MEDIUM}
    }

    .Mui-disabled {
        &::before {
            border-bottom: none;
        }
        .MuiSvgIcon-root  {
            display: none;
        }
    }

    .search-text-base {
        border-bottom       : 2px solid ${COLORS.SECONDARY};
    }

    .menu-logo {
        cursor      : pointer;

        img {
            width   : 25px;
            height  : 25px;
        }
    }
`
