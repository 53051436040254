import styled from "styled-components";

import { COLORS, FONTS, DIMENSIONS } from "Styles/Constants"
import Logo from "Assets/Images/bird.svg";
// import Notification from "Assets/Images/notifications.svg";
import SideBarIcon from "Assets/Images/sidebar.svg";

export const MainWrapper = styled.div`
    text-align      : center;
    font-weight     : ${FONTS.PRIMARY_BOLD};

    .drawer {
        width               : ${DIMENSIONS.SIDEBAR.FULL}px;
        color               : ${COLORS.PRIMARY};
        background-color    : ${COLORS.SECONDARY};
        border-right        : none;
        display             : flex;
        align-items         : center;

        &.mini {
            width : ${DIMENSIONS.SIDEBAR.MINI}px;
        }
    }
`

export const SideBarWrapper = styled.div`
    display             : flex;
    flex-direction      : column;
    margin-top          : 25px;
    width               : 247px;

    &.sidebar {
        &.mini {
            width : 70px;
        }
    }

    .header-title {
        /* margin              : 30px auto; */
        display : flex;
        justify-content : center;

        .logo {
            background-image : url(${Logo});
            width : 40px;
            height : 40px;
            background-position: center;
            background-size: 100%;
            background-repeat: no-repeat;
        }
    }
  
    .sidebarMenuItems {
      display: flex;
      align-items: flex-start;
      justify-content: left;
      margin: 20px 0;
      cursor: pointer;
    }
  
    .sidebarMenuItemsCollapsed {
      justify-content: center;
    }
  
    .sidebarMenuItemsText {
      margin-left: 10px;
    }

    .text {
      margin : 0 0 0 15px;
      font-size : 18px;
      color : #7BC8A9;
  
      &:hover {
        cursor: pointer;
        color : #A0DFC6;
      }
    }

    .links {
        margin : 50px 0;

        .path {
            display : flex;
            align-items : center;
            margin : 10px 0;
            .icon {
                background-image : url(${SideBarIcon});
                width : 40px;
                height : 40px;
                background-size: 330px;
                background-repeat: no-repeat;
                margin : ${props => props.open ? "0" : "0 auto"};

                &.profile {
                    background-position: 14% 75%;
                }
                &.add-members {
                    background-position: 76% 75%;
                }
                &.your-projects {
                    background-position: 64% 75%;
                }
                &.company-lists {
                  background-position: 1% 75%;
                }
                &.feedback {
                    background-position: 51% 75%;
                }
                &.notifications {
                    background-position: 27% 75%;
                }
                &.change-password {
                    background-position: 88% 75%;
                }
                &.privacy-policy {
                    background-position: 39.5% 75%;
                }
                &.pricing {
                    background-position : 99.5% 75%;
                }
                &.logout {
                    background-position: 1% 75%;
                }
                &:hover {
                    cursor: pointer;
                }   
            }
        }
    }

    .btn {
        background-color    : ${COLORS.SECONDARY_LIGHT};
        margin              : 10px 0;
        color               : ${COLORS.PRIMARY};
        text-transform      : capitalize;
        border              : 2px solid ${COLORS.PRIMARY};
        border-radius       : 2em;
        font-family         : ${FONTS.PRIMARY_MEDIUM};
        font-size           : 16px;

        :hover {
            color              : ${COLORS.SECONDARY};
            background-color   : ${COLORS.PRIMARY};
        }
    }

    .member-icon {
        display : flex;
        margin : 10px auto;
        background-image : url(${Logo});
        width : 40px;
        height : 40px;
        background-position: center;
        background-size: 100%;
        background-repeat: no-repeat;

        &:hover {
            cursor: pointer;
        }
    }
`
