import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";

import {
  APIS_END_POINTS,
  URL_FORGOT_PASSWORD,
  URL_PROJECT_LISTING,
  URL_SIGN_UP,
} from "Helpers/Paths";
import { loginUser, updateUserDetails } from "Redux/Auth/Actions";
import BirdIcon from "Assets/Images/black-bird.svg";
import { generateValidationSchema } from "../../../Helpers/Utils";
import { InputWithLabel } from "../../Commons/Commons";
import { Formik } from "formik";
import Api from "../../../Helpers/ApiHandler";
import { MainWrapper, LoginWrapper } from "./Login.style";
import { FormWrapper } from "../SignUp/SignUp.style";

const mapReduxStateToProps = (state) => ({
  isLoggedIn: state.Auth.isLoggedIn,
  userDetails: state.Auth.userDetails,
});

const mapDispatchToProps = (dispatch) => ({
  loginUser: () => dispatch(loginUser()),
  updateUserDetails: (data) => {
    dispatch(updateUserDetails(data));
  },
});

const inputs = [
  {
    label: "Email",
    name: "email",
    validation: { required: true, email: true },
  },
  {
    label: "Password",
    name: "password",
    type: "password",
    validation: { required: true, min: 6 },
  },
];

const initialValues = {
  email: "",
  password: "",
};

const validationSchema = generateValidationSchema(inputs);

class Login extends Component {
  componentDidMount() {
    this.checkIfAlreadyLoggedIn();
  }

  checkIfAlreadyLoggedIn() {
    if (this.props.isLoggedIn) this.props.history.push(URL_PROJECT_LISTING);
  }

  handleLogIn = (values) => {
    const data = {
      email: values.email,
      password: values.password,
    };
    new Api()
      .post(APIS_END_POINTS.USER_SIGN_IN, { data })
      .then((response) => {
        this.subscribeToken(response.data.id);

        localStorage.setItem("accessToken", response.data.token);
        this.props.updateUserDetails(response.data);
        this.props.loginUser();
        if (response.data.roleType === "SUPERADMIN") {
          this.props.history.push(URL_PROJECT_LISTING);
        } else {
          if (response.data.company.isPaymentDone)
            this.props.history.push(URL_PROJECT_LISTING);
          // this.props.history.push(URL_PRICING)
          else this.props.history.push(URL_PROJECT_LISTING);
        }
      })
      .catch((error) => {});
  };

  subscribeToken = (id) => {
    const FCMToken = localStorage.getItem("token");

    if (FCMToken && FCMToken !== "") {
      fetch(
        "https://iid.googleapis.com/iid/v1/" + FCMToken + "/rel/topics/" + id,
        {
          method: "POST",
          headers: new Headers({
            Authorization: "key=" + process.env.REACT_APP_FCM_SERVER_KEY,
          }),
        }
      )
        .then((response) => {
          if (response.status < 200 || response.status >= 400) {
            throw (
              "Error subscribing to topic: " +
              response.status +
              " - " +
              response.text()
            );
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  render() {
    return (
      <MainWrapper>
        <LoginWrapper>
          <div className="header flex">
            <img src={BirdIcon} alt="Bird Logo" />
            <div className="title"> Sign in </div>
          </div>

          <Formik
            initialValues={initialValues}
            onSubmit={this.handleLogIn}
            validationSchema={validationSchema}
          >
            {({ values, touched, errors, handleChange }) => (
              <FormWrapper>
                {inputs.map((input) => (
                  <InputWithLabel
                    className="full-width"
                    name={input.name}
                    placeholder={input.label}
                    label={input.label}
                    type={input.type}
                    value={values[input.name]}
                    onChange={handleChange}
                    validated={touched[input.name] && errors[input.name]}
                  />
                ))}

                <div className="footer flex">
                  {/*<div className="f-password">*/}
                  {/*  <Link to={URL_SIGN_UP}>Don't have an account? Sign Up</Link>*/}
                  {/*</div>*/}
                  <div className="f-password">
                    <Link to={URL_FORGOT_PASSWORD}> Forgot password? </Link>
                  </div>

                  <div className="login-btn">
                    <Button
                      type="submit"
                      variant="outlined"
                      className="btn"
                      fullWidth
                    >
                      Log In
                    </Button>
                  </div>
                </div>
              </FormWrapper>
            )}
          </Formik>
        </LoginWrapper>
      </MainWrapper>
    );
  }
}

export default connect(
  mapReduxStateToProps,
  mapDispatchToProps
)(withRouter(Login));
