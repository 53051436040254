import {
  URL_CHANGE_PASSWORD,
  URL_PRIVACY_POLICY,
  URL_PROJECT_LISTING,
  URL_PROJECT_MEMBERS_LISTING,
  URL_USER_EDIT_PROFILE,
  URL_FEEDBACK_ADD_FEEDBACK,
  URL_NOTIFICATION,
  URL_COMMENTS,
  URL_STATISTICS,
} from "Helpers/Paths";

export const LINKS = [
  {
    title: "Projects",
    name: "your-projects",
    path: URL_PROJECT_LISTING,
  },
  {
    title: "Statistics",
    name: "statistics",
    path: URL_STATISTICS,
  },
  {
    title: "Add Members",
    name: "add-members",
    path: URL_PROJECT_MEMBERS_LISTING,
  },
  {
    title: "Feedback",
    name: "feedback",
    path: URL_FEEDBACK_ADD_FEEDBACK,
  },
  {
    title: "Notifications",
    name: "notifications",
    path: URL_NOTIFICATION,
  },
  {
    title: "Comments",
    name: "comments",
    path: URL_COMMENTS,
  },
  {
    title: "Privacy Policy",
    name: "privacy-policy",
    path: URL_PRIVACY_POLICY,
  },
  {
    title: "User Profile",
    name: "profile",
    path: URL_USER_EDIT_PROFILE,
  },
  {
    title: "Change Password",
    name: "change-password",
    path: URL_CHANGE_PASSWORD,
  },
  {
    title: "LogOut",
    name: "logout",
    path: URL_USER_EDIT_PROFILE,
  },
];
