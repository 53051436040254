import VisibilityIcon from "Assets/Images/visibility.svg";
import BlackHeartIcon from "Assets/Images/black-heart.svg";
import GraphIcon from "Assets/Images/graph.svg";

export const header = {
  userType: "COMPANY_MEMBER",
};

export const EVENT_LOCATIONS_TYPE = ["Location", "Link", "Both"];

export const CheckListKeys = {
  IMAGES: "IMAGES",
  LOCATION: "LOCATION",
  DECISION: "DECISION",
  OVERVIEW: "OVERVIEW",
  TIMELINE: "TIMELINE",
  CONTACTS: "CONTACTS",
};

export let SetupCheckList = [
  {
    key: CheckListKeys.LOCATION,
    isComplete: false,
    title: "LOCATION INFORMATION",
  },
  {
    key: CheckListKeys.OVERVIEW,
    isComplete: false,
    title: "PROJECT DESCRIPTION",
  },
  {
    key: CheckListKeys.TIMELINE,
    isComplete: false,
    title: "PROJECT TIMELINE",
  },
];

export const DashBoardList = [
  {
    title: "Views",
    value: 520,
    image: {
      src: VisibilityIcon,
      alt: "Visibility",
    },
  },
  {
    title: "Likes",
    value: 250,
    image: {
      src: BlackHeartIcon,
      alt: "Like",
    },
  },
  {
    title: "Survey Response",
    value: 150,
    image: {
      src: GraphIcon,
      alt: "Graph",
    },
  },
];

export const QUESTION_TYPES = [
  { name: "Choose One Answer", value: "RADIO" },
  { name: "Select Multiple Answers", value: "CHECKBOX" },
  { name: "Open-Ended Response", value: "TEXT_FIELD" },
  { name: "Ranking", value: "RANKING" },
  { name: "Upload Image", value: "IMAGE_UPLOAD" },
  { name: "Numeric Range", value: "RANGE" },
  { name: "Thumbs Up", value: "UPVOTE" },
];
